import React, { useEffect, useState } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import ProjectCard from "@app/components/project/card";
import ProjectCloneModal from "@app/components/project/clone-modal";
import ProjectFilter from "@app/components/project/filter";
import EmptyList from "@app/components/list/empty";
import confirm from "@app/components/confirm";

import projects from "@app/state/store/projects/list";
import session from "@app/state/store/session";
import profileStore from "@app/state/store/profile";

import "./style/list.scoped.scss";

const ProjectList = observer((props) => {
    const state = useLocalStore(() => ({ focus: null }));
    const [cloneModalOpen, setCloneModalOpen] = useState(false);

    const favoriteProjects = profileStore.data?.favorite?.projects || [];

    // load the list when the component mounts
    useEffect(() => {
        projects.load();
        profileStore.load(session.user._id);
    }, []);

    /**
     * Focus a project card
     */
    const focus = (id) => {
        state.focus = id;
    };

    /**
     * Handle edit event
     */
    const onEdit = (project) => {
        focus(project._id);

        let url = `/projects/${project._id}/edit`;
        props.history.push(url);
    };

    /**
     * Handle delete event
     * @param {MouseEvent} event
     */
    const onRemove = async (project) => {
        let proceed = await confirm("Are you sure you want to remove this project?");
        if (proceed) {
            await projects.remove(project._id);
        }
    };

    // Handle clone event
    const onClone = async (project, event) => {
        setCloneModalOpen(true);
    };

    const onConfigure = (project) => {
        props.history.push(`/r/${project._id}/setup?referrer=/r/${project._id}/`);
    };

    const onFavorite = async (project) => {
        let favorite;
        const isFavorite = favoriteProjects.includes(project._id);

        if (isFavorite) {
            favorite = favoriteProjects.filter((fav) => fav !== project._id);
        } else {
            favorite = [...favoriteProjects, project._id];
        }

        await profileStore.save({ "favorite.projects": favorite });
        await projects.reload();
    };

    return (
        <Page className="projects">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>Projects</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    {session.can("project.manage") && (
                        <NavLink to="/projects/add">
                            <Button type="primary" icon={<PlusOutlined />}>
                                Add Project
                            </Button>
                        </NavLink>
                    )}
                </Page.Header.Right>
            </Page.Header>

            <Filter.Header filter={projects.filter} />

            <Page.Layout>
                <Page.Body filter={projects.filter}>
                    <EmptyList
                        title="No Results Found"
                        show={projects.list.length === 0 && !projects.loading}
                    />

                    <ProjectCloneModal
                        modalOpen={cloneModalOpen}
                        setModalOpen={setCloneModalOpen}
                        project={projects?.list.find((project) => project._id === state.focus)}
                        projectState={projects}
                    />

                    <div className="card-list">
                        {projects.list.map((project) => {
                            return (
                                <NavLink
                                    to={`/r/${project._id}/`}
                                    onClick={() => {
                                        focus(project._id);
                                    }}
                                    key={project._id}
                                >
                                    <ProjectCard
                                        className="card"
                                        focus={state.focus === project._id}
                                        project={project}
                                        onEdit={onEdit}
                                        onRemove={onRemove}
                                        onClone={onClone}
                                        onConfigure={onConfigure}
                                        onFavorite={onFavorite}
                                        favoriteProjects={favoriteProjects}
                                    />
                                </NavLink>
                            );
                        })}
                    </div>
                </Page.Body>
                <ProjectFilter filter={projects.filter} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={projects.filter} />
            </Page.Footer>
        </Page>
    );
});

export default ProjectList;
