import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import MarkdownIt from "markdown-it";
import Turndown from "turndown";

import "quill/dist/quill.snow.css";
// import "../markdown-editor/style/editor.scss";

const options = {
    theme: "snow",
    modules: {
        toolbar: "#toolbar",
        clipboard: {
            matchVisual: false,
        },
    },
    formats: ["script"],
    placeholder: "Enter caption...",
};

// Configure markdown
const md = new MarkdownIt();
md.set({ html: true });

const td = new Turndown({ headingStyle: "atx" });
td.keep(["sub", "sup"]);

const escapes = [
    [/\\/g, "\\\\"],
    [/\*/g, "\\*"],
    [/^-/g, "\\-"],
    [/^\+ /g, "\\+ "],
    [/^(=+)/g, "\\$1"],
    [/^(#{1,6}) /g, "\\$1 "],
    [/`/g, "\\`"],
    [/^~~~/g, "\\~~~"],
    // [/\[/g, "\\["],
    // [/\]/g, "\\]"],
    [/^>/g, "\\>"],
    [/_/g, "\\_"],
    [/^(\d+)\. /g, "$1\\. "],
];

// overriding trundownService's default escape function
td.escape = (string) => {
    return escapes.reduce(
        (accumulator, escape) => accumulator.replace(escape[0], escape[1]),
        string,
    );
};

const CaptionInput = ({ value = "", onChange, children, ...rest }) => {
    const { quill, quillRef } = useQuill(options);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (quill && !loaded) {
            const renderContent = value.replace(/\n/g, " ");
            const mdContent = md.render(renderContent);
            quill.clipboard.dangerouslyPasteHTML(`${mdContent}`);

            const textChangeHandler = () => {
                const html = quill.container.firstChild.innerHTML;
                const markdown = td.turndown(html);
                onChange && onChange(markdown);
            };

            quill.on("text-change", textChangeHandler);
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill, value, loaded, onChange]);

    return (
        <div {...rest}>
            <div id="toolbar">
                <button className="ql-script" value="sub"></button>
                <button className="ql-script" value="super"></button>
            </div>
            <div ref={quillRef} />
            {children}
        </div>
    );
};

export default CaptionInput;
