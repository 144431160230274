import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { isNodeSelection } from "@tiptap/core";
import DictIcon from "../../icons/dictionary";
import ABBRIcon from "../../icons/abbreviation";
import CommentIcon from "../../icons/comment";
import { Dropdown, Menu } from "antd";
import { default as Icon } from "@ant-design/icons";
import { antdHelpers } from "@app/util";

import { isInTable } from "@tiptap/pm/tables";

import {
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    ArrowUpOutlined,
    EditOutlined,
    BookOutlined,
    LinkOutlined,
} from "@ant-design/icons";

import { ReportDocumentSection } from "@app/constants";

import checkIfInFootnote from "@app/components/report-document/editor/modules/table/utils/is-in-footnote.js";

import TableRowAdd from "../../icons/table-row-add";
import TableColumnAdd from "../../icons/table-column-add";
import TableRowDelete from "../../icons/table-row-delete";
import TableColumnDelete from "../../icons/table-column-delete";
import TableRowHeader from "../../icons/table-row-header";
import TableColumnHeader from "../../icons/table-column-header";
import TableRowSelect from "../../icons/table-row-select";
import TableColumnSelect from "../../icons/table-column-select";
import TableDelete from "../../icons/table-delete";
import TableCellHeader from "../../icons/table-cell-header";

import "./context-menu.scoped.scss";

export const ContextMenu = observer(({ editor, children, templateMode }) => {
    const [menu, setMenu] = useState(<></>);
    let contextTimer;

    const handleContextMenu = (event) => {
        setMenu(<></>);
        // this is a hack to prevent the menu being build twice and when the selection is changed
        contextTimer = setTimeout(() => {
            setMenu(buildMenu());
        }, 500);
    };

    useEffect(() => {
        if (editor) {
            editor.view.dom.addEventListener("contextmenu", handleContextMenu);
            return () => {
                editor.view.dom.removeEventListener("contextmenu", handleContextMenu);
                clearTimeout(contextTimer);
            };
        }
    }, [editor]);

    if (!editor) {
        return;
    }

    const createDictionaryEntry = () => {
        editor.commands.createDictionaryEntry();
    };

    const createAbbreviationEntry = () => {
        editor.commands.createAbbreviationEntry();
    };

    const createComment = () => {
        editor.commands.createComment();
    };

    const insertReport = () => {
        editor.commands.selectReport();
    };

    const createExternalLink = () => {
        editor.commands.toggleExternalLink();
    };

    const showCommonOptions = () => {
        const { selection } = editor.state;

        // do not show the menu on node selection
        if (isNodeSelection(selection)) {
            return false;
        }

        if (!editor.commands.hasTextSelection()) {
            return false;
        }

        return true;
    };

    const showTableOptions = () => {
        if (isInTable(editor.state)) {
            return true;
        }

        return false;
    };

    // const showImageOptions = () => {
    //     const { selection } = editor.state;

    //     if (isNodeSelection(selection)) {
    //         if (selection.node.type.name === "image") {
    //             return true;
    //         }
    //     }

    //     return false;
    // };

    const showDictionaryOptions = () => {
        const { selection } = editor.state;

        if (isNodeSelection(selection)) {
            if (selection.node.type.name === "dictionary") {
                return true;
            }
        }

        return false;
    };

    const showAbbreviationOptions = () => {
        const { selection } = editor.state;

        if (isNodeSelection(selection)) {
            if (selection.node.type.name === "abbreviation") {
                return true;
            }
        }

        return false;
    };

    const isOnCustomObject = () => {
        const { selection } = editor.state;

        if (isNodeSelection(selection)) {
            if (selection.node.type.name === ReportDocumentSection.CUSTOM_OBJECT.NODE_NAME) {
                return true;
            }
        }

        return false;
    };

    const buildMenu = () => {
        const { selection } = editor.state;

        const isInFootnote = checkIfInFootnote({ editor });
        const showCommonItems = editor?.isEditable && showCommonOptions();
        const showInsertReport = editor?.isEditable && !templateMode && !isInFootnote;
        const showTableItems = editor?.isEditable && showTableOptions();
        // const showImageItems = editor?.isEditable && showImageOptions();
        const showDictionaryItems = editor?.isEditable && showDictionaryOptions();
        const showAbbreviationItems = editor?.isEditable && showAbbreviationOptions();
        const canAddComment = editor?.can().createComment();
        const showEditCustomObject = editor?.isEditable && isOnCustomObject();

        const getDividerKey = () => `divider-${Math.random()}`;

        const menuItems = (
            <Menu className="menu">
                {showCommonItems && [
                    <Menu.Item
                        key="createDictionary"
                        children={"Create a dictionary entry"}
                        icon={<Icon component={DictIcon} />}
                        onClick={createDictionaryEntry}
                    />,
                    <Menu.Item
                        key="createAbbreviation"
                        className="abbreviation"
                        children={"Create an abbreviation entry"}
                        icon={<Icon component={ABBRIcon} />}
                        onClick={createAbbreviationEntry}
                    />,
                    <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                    <Menu.Item
                        key="createExternalLink"
                        className="abbreviation"
                        children={"Create an external link"}
                        icon={<Icon component={LinkOutlined} />}
                        onClick={createExternalLink}
                    />,
                ]}

                {showEditCustomObject && [
                    <Menu.Item
                        key="editCustomObject"
                        children={"Edit Custom Object"}
                        icon={<EditOutlined />}
                        onClick={() => {
                            editor.emit(
                                ReportDocumentSection.CUSTOM_OBJECT.SHOW_EDIT_MODAL_EVENT,
                                selection.node.attrs,
                            );
                        }}
                    />,
                    <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                ]}

                {showInsertReport && [
                    showCommonItems && (
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />
                    ),
                    <Menu.Item
                        key="insertReport"
                        className="report"
                        children="Insert report"
                        icon={<Icon component={BookOutlined} />}
                        onClick={insertReport}
                    />,
                ]}

                {!templateMode && [
                    showInsertReport && (
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />
                    ),
                    <Menu.Item
                        key="createComment"
                        className="comment"
                        children={"Add new comment"}
                        icon={<Icon component={CommentIcon} />}
                        onClick={createComment}
                        disabled={!canAddComment}
                    />,
                ]}

                {showTableItems &&
                    !isInFootnote && [
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,

                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                        <Menu.SubMenu
                            title={"Insert Row"}
                            icon={<Icon component={TableRowAdd} />}
                            key="insertRowMenu"
                        >
                            <Menu.Item
                                key="addTableRowAbove"
                                children={"Above"}
                                icon={<ArrowUpOutlined />}
                                onClick={() => editor.chain().focus().addRowBefore().run()}
                            />
                            <Menu.Item
                                key="addTableRowBelow"
                                children={"Below"}
                                icon={<ArrowDownOutlined />}
                                onClick={() => editor.chain().focus().addRowAfter().run()}
                            />
                        </Menu.SubMenu>,
                        <Menu.SubMenu
                            title={"Insert Column"}
                            icon={<Icon component={TableColumnAdd} />}
                            key="insertColumnMenu"
                        >
                            <Menu.Item
                                key="addTableColumnBefore"
                                children={"Left"}
                                icon={<ArrowLeftOutlined />}
                                onClick={() => editor.chain().focus().addColumnBefore().run()}
                            />
                            <Menu.Item
                                key="addTableColumnAfter"
                                children={"Right"}
                                icon={<ArrowRightOutlined />}
                                onClick={() => editor.chain().focus().addColumnAfter().run()}
                            />
                        </Menu.SubMenu>,
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                        <Menu.Item
                            key="selectTableRow"
                            children={"Select Row"}
                            icon={<Icon component={TableRowSelect} />}
                            onClick={() =>
                                editor.chain().focus().selectWholeRowOrColumn("row").run()
                            }
                        />,
                        <Menu.Item
                            key="selectTableColumn"
                            children={"Select Column"}
                            icon={<Icon component={TableColumnSelect} />}
                            onClick={() =>
                                editor.chain().focus().selectWholeRowOrColumn("column").run()
                            }
                        />,
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                        <Menu.Item
                            key="toggleTableHeaderRow"
                            children={"Toggle Header Row"}
                            icon={<Icon component={TableRowHeader} />}
                            onClick={() => editor.chain().focus().toggleHeaderRow().run()}
                        />,
                        <Menu.Item
                            key="toggleTableHeaderColumn"
                            children={"Toggle Header Column"}
                            icon={<Icon component={TableColumnHeader} />}
                            onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
                        />,
                        <Menu.Item
                            key="toggleTableHeaderCell"
                            children={"Toggle Header Cell"}
                            icon={<Icon component={TableCellHeader} />}
                            onClick={() =>
                                editor.chain().focus().toggleHeaderCellOnSelection().run()
                            }
                        />,
                        <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                        <Menu.Item
                            key="deleteTableRow"
                            children={"Delete Row"}
                            icon={<Icon component={TableRowDelete} />}
                            onClick={() => editor.chain().focus().deleteRow().run()}
                        />,
                        <Menu.Item
                            key="deleteTableColumn"
                            children={"Delete Column"}
                            icon={<Icon component={TableColumnDelete} />}
                            onClick={() => editor.chain().focus().deleteColumn().run()}
                        />,
                        <Menu.Item
                            key="deleteTable"
                            children={"Delete Table"}
                            icon={<Icon component={TableDelete} />}
                            onClick={() => editor.chain().focus().deleteTable().run()}
                        />,
                    ]}

                {showTableItems && [
                    editor.can().createFootnoteAlternative() ? (
                        <Menu.Item
                            key="addTableFootnote"
                            children={"Add Footnote"}
                            icon={<EditOutlined />}
                            onClick={() => editor.chain().focus().createFootnoteAlternative().run()}
                        />
                    ) : (
                        <Menu.Item
                            key="addTableFootnote"
                            children={"Remove Footnote"}
                            icon={<EditOutlined />}
                            onClick={() => editor.chain().focus().removeFootnoteAlternative().run()}
                        />
                    ),
                ]}

                {/* {showImageItems && [
                    <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                ]} */}

                {showDictionaryItems && [
                    <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                    <Menu.Item
                        key="editDictionary"
                        children={"Edit Dictionary"}
                        icon={<EditOutlined />}
                        onClick={() => {
                            const entry = editor.storage.dictionary.store.use(
                                selection.node.attrs.key,
                            );
                            editor.emit("dictionaryEdit", entry);
                        }}
                    />,
                ]}

                {showAbbreviationItems && [
                    <Menu.Divider key={`${getDividerKey()}`} className={"divider"} />,
                    <Menu.Item
                        key="editAbbreviation"
                        children={"Edit Abbreviation"}
                        icon={<EditOutlined />}
                        onClick={() => {
                            const entry = editor.storage.abbreviation.store.use(
                                selection.node.attrs.key,
                            );
                            editor.emit("abbreviationEdit", entry);
                        }}
                    />,
                ]}
            </Menu>
        );

        if (antdHelpers.hasMenuItems(menuItems)) {
            return menuItems;
        } else {
            return <></>;
        }
    };

    return (
        <Dropdown
            overlay={menu}
            overlayClassName="context-menu"
            trigger={"contextMenu"}
            getPopupContainer={() => editor.view.dom.parentNode}
            destroyPopupOnHide={true}
        >
            {children}
        </Dropdown>
    );
});

export default ContextMenu;
