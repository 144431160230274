import _ from "lodash";
import { v4 as uuid } from "uuid";
import { default as TableExtension, createTable } from "@tiptap/extension-table";
import { TextSelection } from "prosemirror-state";
import {
    isInTable,
    tableEditing,
    CellSelection,
    TableMap,
    selectionCell,
    tableNodeTypes,
    selectedRect,
} from "@tiptap/pm/tables";

import documentStore from "@app/state/store/report-document/document";
import { inLandscape } from "@app/components/report-document/editor/utils";

import tr from "../utils/transaction";

import { columnResizing } from "./column-resizing";
import { TableView } from "./table-view";
import keepSelectionPlugin from "./keep-selection-plugin";

import TableUtils from "./utils/table-utils";
import { mergeCells } from "./utils/merge-cells";
import isInFootnote from "./utils/is-in-footnote";
import { cancelNewRowAfterFootnote } from "./cancel-tab";

export const Table = TableExtension.extend({
    onTransaction(args) {
        let update = false;
        const editor = args.editor;

        if (!tr.isEdit(args)) {
            return;
        }

        const added = tr.addedNodes(args, { type: this.name });
        if (added.length) {
            update = true;
        }

        const removed = tr.removedNodes(args, { type: this.name });
        if (removed.length) {
            update = true;
        }

        if (update && editor.sectionId) {
            const content = editor.getJSON();
            documentStore.processSection(editor.sectionId, content);
        }
    },

    addAttributes() {
        return {
            id: {
                default: "",
                parseHTML: (table) => {
                    if (!table.hasAttribute("data-diff-mode") || !table.hasAttribute("id")) {
                        return uuid();
                    }

                    return table.getAttribute("id");
                },
            },
            caption: {
                default: "",
            },
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
            },
        };
    },

    addStorage() {
        return {
            store: {},
        };
    },

    addOptions(args) {
        return {
            HTMLAttributes: {},
            resizable: false,
            handleWidth: 5,
            cellMinWidth: 0,
            View: TableView,
            lastColumnResizable: true,
            allowTableNodeSelection: false,
        };
    },

    onBeforeCreate() {
        this.storage.store = this.options.store || {};
        this.options.metadata = this.options.metadata || {};
    },

    addCommands() {
        return {
            ...this.parent?.(),
            insertTable: (args) => ({ editor, commands, tr, dispatch }) => {
                const cursor = tr.selection.$cursor;
                if (!cursor || cursor.depth > 2) {
                    return false;
                }

                if (cursor.depth === 2 && cursor.parent.type.name !== "paragraph") {
                    return false;
                }

                if (dispatch) {
                    const { rows = 3, cols = 3, caption = "", withHeader = true } = args;
                    const node = createTable(editor.schema, rows, cols, withHeader);

                    // clone the attributes as they are getting overwritten when
                    // adding new tables
                    node.attrs = _.cloneDeep(node.attrs);
                    node.attrs.id = uuid();

                    if (caption) {
                        node.attrs.caption = caption;
                    }

                    node.content.forEach((row) =>
                        row.content.forEach((cell) => {
                            if (["tableHeader", "tableCell"].includes(cell.type)) {
                                cell.attrs.colwidth = 215;
                            }

                            if (cell.type.name === "tableHeader") {
                                cell.attrs.verticalAlign = "middle";
                            }
                        }),
                    );

                    const offset = tr.selection.anchor + 1;
                    tr.replaceSelectionWith(node)
                        .scrollIntoView()
                        .setSelection(TextSelection.near(tr.doc.resolve(offset)));
                }

                return true;
            },

            deleteTable: () => ({ state, dispatch }) => {
                let $pos = state.selection.$anchor;
                for (let d = $pos.depth; d > 0; d--) {
                    let node = $pos.node(d);
                    if (node.type.name === "table") {
                        // Check if the table is wrapped in a tableWrapper
                        const parentNode = $pos.node(d - 1);
                        if (parentNode.type.name === "tableWrapper") {
                            // If so, delete the tableWrapper
                            if (dispatch) {
                                const tr = state.tr;
                                const deleteFrom = $pos.before(d - 1);
                                const deleteTo = $pos.after(d - 1);
                                tr.delete(deleteFrom, deleteTo);
                                dispatch(tr.scrollIntoView());
                            }
                        } else {
                            // If not, delete just the table
                            if (dispatch) {
                                const tr = state.tr;
                                const deleteFrom = $pos.before(d);
                                const deleteTo = $pos.after(d);
                                tr.delete(deleteFrom, deleteTo);
                                dispatch(tr.scrollIntoView());
                            }
                        }
                        return true;
                    }
                }

                return false;
            },

            showInsertTable: () => ({ editor }) => {
                editor.emit("showInsertTable");
            },

            editTableCaption: () => ({ editor }) => {
                if (isInTable(editor.state)) {
                    const depth = inLandscape(editor) ? 2 : 1;
                    const table = editor.state.selection?.$head?.node(depth);

                    if (table?.type?.name === this.name) {
                        return true;
                    }
                }

                return false;
            },

            splitCellCustom: (splitDirection) => (api) => {
                const tableUtil = new TableUtils(api);

                if (isInFootnote(api)) {
                    return false;
                }

                try {
                    const { tr, result } =
                        splitDirection === "horizontal"
                            ? tableUtil.splitCellHorizontally()
                            : tableUtil.splitCellVertically();

                    if (!result || !tr.docChanged) {
                        return false;
                    }

                    if (api.dispatch) {
                        api.dispatch(tr);
                    }

                    return true;
                } catch (error) {
                    return false;
                }
            },
            selectWholeRowOrColumn: (direction) => (api) => {
                if (isInFootnote(api)) {
                    return false;
                }
                const { state, dispatch } = api;

                const cell = selectionCell(state);

                if (!cell) {
                    console.error("No cell found");
                    return false;
                }

                const table = cell.node(-1);
                if (!table || table.type.spec.tableRole !== "table") {
                    console.error("No table found");
                    return false;
                }

                const tableMap = TableMap.get(table);
                const tableStart = cell.start(-1);

                // Determine the row and column of the selected cell
                const cellIndex = tableMap.map.findIndex((pos) => pos + tableStart === cell.pos);
                if (cellIndex === -1) {
                    console.error("No cell found in the table map");
                    return false;
                }

                const row = Math.floor(cellIndex / tableMap.width);
                const col = cellIndex % tableMap.width;

                let startCellPos, endCellPos;

                if (direction === "row") {
                    startCellPos = tableMap.positionAt(row, 0, table);
                    endCellPos = tableMap.positionAt(row, tableMap.width - 1, table);
                } else if (direction === "column") {
                    startCellPos = tableMap.positionAt(0, col, table);
                    const tableUtil = new TableUtils(api);

                    // if last row is footnote, reduce the row selection by 1
                    if (tableUtil.isLastRowFootnote()) {
                        endCellPos = tableMap.positionAt(tableMap.height - 2, col, table);
                    } else {
                        endCellPos = tableMap.positionAt(tableMap.height - 1, col, table);
                    }
                } else {
                    console.error("Invalid direction");
                    return false;
                }

                const startPos = tableStart + startCellPos;
                const endPos = tableStart + endCellPos;

                if (
                    !state.doc.resolve(startPos).nodeAfter ||
                    !state.doc.resolve(endPos).nodeAfter
                ) {
                    console.error("Invalid cell positions");
                    return false;
                }

                const cellSelection = CellSelection.create(state.doc, startPos, endPos);

                dispatch(state.tr.setSelection(cellSelection));

                return true;
            },

            mergeCellsDirectly: () => (api) => {
                if (isInFootnote(api)) {
                    return false;
                }

                try {
                    return mergeCells(api);
                } catch (error) {
                    return false;
                }
            },

            toggleHeaderCellOnSelection: () => (api) => {
                const { state, dispatch } = api;

                if (!isInTable(state)) {
                    return false;
                }

                if (isInFootnote(api)) {
                    return false;
                }

                const types = tableNodeTypes(state.schema);
                const rect = selectedRect(state);
                const tr = state.tr;

                const cells = rect.map.cellsInRect(rect);
                const nodes = cells.map(function (pos) {
                    return rect.table.nodeAt(pos);
                });

                const allHeaderCells = nodes.every(function (node) {
                    return node.type === types.header_cell;
                });

                for (var i = 0; i < cells.length; i++) {
                    var nodeType = allHeaderCells ? types.cell : types.header_cell;
                    tr.setNodeMarkup(rect.tableStart + cells[i], nodeType, nodes[i].attrs);
                }

                if (dispatch) {
                    dispatch(tr);
                }

                return true;
            },
            toggleHeaderRow: () => (api) => {
                const { state, dispatch } = api;

                if (!isInTable(state)) {
                    return false;
                }

                if (isInFootnote(api)) {
                    return false;
                }

                const types = tableNodeTypes(state.schema);
                const rect = selectedRect(state);
                const tr = state.tr;

                const cells = rect.map.cellsInRect({
                    left: 0,
                    top: rect.top,
                    right: rect.map.width,
                    bottom: rect.bottom,
                });
                const nodes = cells.map(function (pos) {
                    return rect.table.nodeAt(pos);
                });

                const allHeaderCells = nodes.every(function (node) {
                    return node.type === types.header_cell;
                });

                for (var i = 0; i < cells.length; i++) {
                    var nodeType = allHeaderCells ? types.cell : types.header_cell;
                    tr.setNodeMarkup(rect.tableStart + cells[i], nodeType, nodes[i].attrs);
                }

                if (dispatch) {
                    dispatch(tr);
                }

                return true;
            },
            toggleHeaderColumn: () => (api) => {
                const { state, dispatch } = api;

                if (!isInTable(state)) {
                    return false;
                }

                if (isInFootnote(api)) {
                    return false;
                }

                const types = tableNodeTypes(state.schema);
                const rect = selectedRect(state);
                const tr = state.tr;

                const cells = rect.map.cellsInRect({
                    left: rect.left,
                    top: 0,
                    right: rect.right,
                    bottom: rect.map.height,
                });
                const nodes = cells.map(function (pos) {
                    return rect.table.nodeAt(pos);
                });

                const tableUtil = new TableUtils(api);
                const isFootnoteCell = tableUtil.isFootnoteCell(nodes[nodes.length - 1]);

                const allHeaderCells = nodes
                    .slice(0, isFootnoteCell ? -1 : undefined)
                    .every(function (node) {
                        return node.type === types.header_cell;
                    });

                for (var i = 0; i < cells.length; i++) {
                    // Skip footnote cells when toggling header
                    if (i === cells.length - 1 && isFootnoteCell) {
                        continue;
                    }

                    const nodeType = allHeaderCells ? types.cell : types.header_cell;
                    tr.setNodeMarkup(rect.tableStart + cells[i], nodeType, nodes[i].attrs);
                }

                if (dispatch) {
                    dispatch(tr);
                }

                return true;
            },
            createFootnoteAlternative: () => (api) => {
                const { state, dispatch } = api;
                const { $from } = state.selection;
                let depth = $from.depth;
                let table, tablePos;

                while (depth > 0) {
                    const node = $from.node(depth);
                    if (node.type.name === "table") {
                        table = node;
                        tablePos = $from.before(depth);
                        break;
                    }
                    depth--;
                }

                if (!table) {
                    return false;
                }

                const lastRow = table.lastChild;

                if (!lastRow || lastRow.attrs.isFootnote) {
                    return false;
                }

                if (dispatch) {
                    const tr = state.tr;
                    const schema = state.schema;
                    const tableUtil = new TableUtils(api);
                    const colCount = tableUtil.getColumnCount();

                    const footnoteCell = schema.nodes.tableCell.create(
                        { colspan: colCount },
                        schema.nodes.paragraph.create({}, schema.text("Table footnote")),
                    );
                    const footnoteRow = schema.nodes.tableRow.create(
                        { isFootnote: true },
                        footnoteCell,
                    );

                    const tableEnd = tablePos + table.nodeSize - 1;
                    tr.insert(tableEnd, footnoteRow);

                    dispatch(tr);
                }

                return true;
            },
            removeFootnoteAlternative: () => ({ state, dispatch }) => {
                const { $from } = state.selection;
                let depth = $from.depth;
                let table, tablePos;

                while (depth > 0) {
                    const node = $from.node(depth);
                    if (node.type.name === "table") {
                        table = node;
                        tablePos = $from.before(depth);
                        break;
                    }
                    depth--;
                }

                if (!table) {
                    return false;
                }

                if (dispatch) {
                    const tr = state.tr;
                    const lastRow = table.lastChild;

                    if (lastRow && lastRow.attrs.isFootnote) {
                        const lastRowPos = tablePos + table.nodeSize - 2 - lastRow.nodeSize;
                        tr.delete(lastRowPos, lastRowPos + lastRow.nodeSize);
                        dispatch(tr);
                        return true;
                    }
                }

                return false;
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            columnResizing({
                handleWidth: this.options.handleWidth,
                cellMinWidth: 25,
                maxTableWidth: this.options.maxTableWidth,
                View: this.options.View,
                tables: this.storage.store.tables,
                editor: this.editor,
                lastColumnResizable: true,
                isEditable: this.editor.isEditable,
            }),

            tableEditing({
                allowTableNodeSelection:
                    this.editor.isEditable && this.options.allowTableNodeSelection,
            }),
            keepSelectionPlugin(),
            cancelNewRowAfterFootnote(),
        ];
    },
});

export default Table;
