import { Node } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";

import { PluginKey } from "@tiptap/pm/state";
import Suggestion from "@tiptap/suggestion";

import MentionList from "@app/components/symbol-picker/list";
import symbols from "@app/components/symbol-picker/symbols";

const char = "$";

export const SymbolPicker = Node.create({
    name: "symbol",
    group: "inline",
    inline: true,
    atom: true,
    selectable: false,

    addOptions() {
        return {
            HTMLAttributes: {},
            renderLabel({ options, node }) {
                return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
            },
            suggestion: {
                char,
                pluginKey: new PluginKey("symbol"),
                command: ({ editor, range, props }) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(" ");

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    editor.chain().focus().insertContentAt(range, props.id.value).run();

                    const selection = window.getSelection();

                    if (selection) {
                        selection.collapseToEnd();
                    }
                },
                allow: ({ state, range }) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    const allow = !!$from.parent.type.contentMatch.matchType(type);

                    return allow;
                },
                items: ({ query }) => {
                    return symbols.filter((item) =>
                        item.name.toLowerCase().startsWith(query.toLowerCase()),
                    );
                },
                render: () => {
                    let component;
                    let popup;

                    return {
                        onStart: (props) => {
                            component = new ReactRenderer(MentionList, {
                                props,
                                editor: props.editor,
                            });

                            if (!props.clientRect) {
                                return;
                            }

                            popup = tippy("body", {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: "manual",
                                placement: "bottom-start",
                            });
                        },

                        onUpdate(props) {
                            component.updateProps(props);

                            if (!props.clientRect) {
                                return;
                            }

                            popup[0].setProps({
                                getReferenceClientRect: props.clientRect,
                            });
                        },

                        onKeyDown(props) {
                            if (props.event.key === "Escape") {
                                popup[0].hide();

                                return true;
                            }

                            return component.ref?.onKeyDown(props);
                        },

                        onExit() {
                            popup[0].destroy();
                            component.destroy();
                        },
                    };
                },
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    const { selection } = state;
                    const { empty, anchor } = selection;

                    if (!empty || anchor <= 1) {
                        return false;
                    }

                    const charBeforeCursor = state.doc.textBetween(anchor - 1, anchor);

                    const symbolValues = symbols.map((item) => item.value);

                    if (symbolValues.includes(charBeforeCursor)) {
                        tr.insertText(char, anchor - 1, anchor);
                        return true;
                    }

                    return false;
                }),
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion,
            }),
        ];
    },
});
