import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import _ from "lodash";

import Group from "../../group";
import Item from "./item";
import Form from "./form";

import confirm from "@app/components/confirm";
import List from "@app/components/list/ordered";

const CustomDatabases = observer(({ customDatabases = [], onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        edit: false,
        editing: undefined,
        databases: [],
    }));

    useEffect(() => {
        state.databases = _.cloneDeep(customDatabases);
    }, [customDatabases]);

    const onCancel = () => {
        state.edit = false;
        state.editing = undefined;
    };

    const updateState = async ({ databases }) => {
        onChange && (await onChange(databases));
    };

    const reorder = async (action) => {
        let [item] = state.databases.splice(action.from, 1);
        state.databases.splice(action.to, 0, item);
        await updateState({ databases: state.databases });
    };

    const onRemove = async (item) => {
        let proceed = await confirm(
            "You are about to delete this database.  Do you want to continue?",
        );

        if (proceed) {
            const newList = _.cloneDeep(state.databases);
            const index = newList.findIndex((entry) => entry._id === item._id);

            if (index !== -1) {
                newList.splice(index, 1);
                await updateState({ databases: newList });
            }
        }

        onCancel();
    };

    const onAdd = async (name) => {
        const newList = _.cloneDeep([...state.databases, { name }]);
        await updateState({ databases: newList });

        onCancel();
    };

    const onEdit = async (item) => {
        const newList = _.cloneDeep(state.databases);
        const index = newList.findIndex((entry) => entry._id === state.editing._id);

        if (index !== -1) {
            newList[index].name = item.name;
            await updateState({ databases: newList });
        }
        onCancel();
    };

    return (
        <>
            <Group
                title={"Custom Databases"}
                className="customDatabases"
                onAdd={
                    readonly
                        ? undefined
                        : () => {
                              state.edit = true;
                              state.editing = undefined;
                          }
                }
            >
                <List onChange={!readonly && reorder}>
                    {state.databases.map((item, index) => (
                        <Item
                            index={index}
                            key={item.id}
                            item={item}
                            readonly={readonly}
                            onEdit={
                                readonly
                                    ? undefined
                                    : () => {
                                          state.edit = true;
                                          state.editing = item;
                                      }
                            }
                            onRemove={() => onRemove(item)}
                        />
                    ))}
                </List>
            </Group>

            {!readonly && state.edit && (
                <Form
                    item={state.editing}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    onCancel={() => {
                        state.edit = false;
                        state.editing = undefined;
                    }}
                />
            )}
        </>
    );
});

export default CustomDatabases;
