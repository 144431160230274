import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import Group from "@app/components/setup/group";

import "../style/databases.scoped.scss";

const Item = observer(({ index, item, onEdit, onRemove, readonly }) => {
    return (
        <Group.Item id={item._id || "new"} index={index} disableDrag={readonly}>
            <div className="customDatabase">
                <div className="text">{item.name}</div>

                {!readonly && (
                    <div className="actions">
                        <div className="icons">
                            <Button onClick={onEdit} type="icon" icon={<EditOutlined />}></Button>

                            <Button
                                onClick={onRemove}
                                type="icon"
                                danger
                                icon={<DeleteOutlined />}
                            ></Button>
                        </div>
                    </div>
                )}
            </div>
        </Group.Item>
    );
});

export default Item;
