import React from "react";
import { observer } from "mobx-react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined, FileExcelOutlined, FileWordOutlined } from "@ant-design/icons";

const ExportMenu = observer(({ project, type, reportIds, options }) => {
    const format = options?.export;
    const exportBaseURL = `/api/project/${project}/reports/new/${type}/export?`;

    const exportUrl = (format) => {
        return `${exportBaseURL}format=${format}&reportIds=${reportIds?.join(",")}`;
    };

    const exportMenu = (
        <Menu>
            {(!format || format === "excel") && (
                <Menu.Item key="excel" icon={<FileExcelOutlined />}>
                    <a href={exportUrl("excel")} target="_blank" rel="noopener noreferrer">
                        Excel
                    </a>
                </Menu.Item>
            )}
            {(!format || format === "word") && (
                <Menu.Item key="excel" icon={<FileWordOutlined />}>
                    <a href={exportUrl("word")} target="_blank" rel="noopener noreferrer">
                        Word
                    </a>
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <Dropdown overlay={exportMenu} trigger="click" placement="bottomRight">
            <Button>
                Export <DownOutlined />
            </Button>
        </Dropdown>
    );
});

export default ExportMenu;
