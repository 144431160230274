import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";

import Databases from "@app/components/setup/search/databases";
import CustomDatabases from "@app/components/setup/search/custom-databases";
import AutoUpdateSearch from "@app/components/setup/search/auto-update-search";
import Style from "@app/components/setup/reference/style";
import Tags from "@app/components/setup/tag/group";
import notify from "@app/components/notify";

import setup from "@app/state/store/projects/setup";
import report from "@app/state/store/report";

const General = observer(() => {
    const history = useHistory();
    const config = setup.config;
    const readonly = report.readOnly || setup.isComplete;

    if (!config) {
        return;
    }

    /**
     * Update the config value
     */
    const update = async (updates, args = {}) => {
        try {
            await setup.updateConfig(
                {
                    ...updates,
                },
                { preview: true },
            );
        } catch (ex) {
            const message = args.error || "Error updating the configuration";
            notify.error(message);
        }
    };

    /**
     * Close the page
     */
    const close = async (args) => {
        history.push("../setup");
    };

    /**
     * Render the configuration page
     */
    return (
        <Page>
            <Page.Header title="General" closable onClose={close} />
            <Page.Body maxWidth={1000}>
                <Databases
                    config={config.search}
                    onChange={(search) => {
                        update({ search });
                    }}
                    readonly={readonly}
                />

                <CustomDatabases
                    customDatabases={config.search.customDatabases}
                    onChange={(customDatabases) => {
                        return update({
                            search: {
                                ...config.search,
                                customDatabases,
                            },
                        });
                    }}
                    readonly={readonly}
                />

                <AutoUpdateSearch
                    config={config.search}
                    onChange={(search) => {
                        update({ search });
                    }}
                    readonly={readonly}
                />

                <Style
                    config={config.reference}
                    onChange={(reference) => {
                        update({ reference });
                    }}
                    readonly={readonly}
                />
                <Tags
                    title="Content Tags"
                    list={config.contentTags}
                    onChange={(tags) => {
                        const error =
                            `Unable to remove the tag. ` +
                            `Please make sure it is not used in the project before trying again.`;

                        update({ contentTags: tags }, { error });
                    }}
                    readonly={readonly}
                />
                <Tags
                    title="Article Tags"
                    list={config.articleTags}
                    onChange={(tags) => {
                        const error =
                            `Unable to remove the tag. ` +
                            `Please make sure it is not used in the project before trying again.`;

                        update({ articleTags: tags }, { error });
                    }}
                    readonly={readonly}
                />
                <Tags
                    title="Document Tags"
                    list={config.documentTags}
                    onChange={(tags) => {
                        const error =
                            `Unable to remove the tag. ` +
                            `Please make sure it is not used in the project before trying again.`;

                        update({ documentTags: tags }, { error });
                    }}
                    readonly={readonly}
                />
            </Page.Body>
        </Page>
    );
});

export default General;
