import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";

import Databases from "@app/components/setup/search/databases";
import CustomDatabases from "@app/components/setup/search/custom-databases";
import Style from "@app/components/setup/reference/style";
import Tags from "@app/components/setup/tag/group";

import client from "@app/state/store/clients/details";

const General = observer(() => {
    const history = useHistory();
    const config = client?.config;

    if (!config) {
        return;
    }

    /**
     * Update the config value
     */
    const update = async (updates) => {
        // eslint-disable-next-line no-unused-vars
        const { _id, rest } = config;
        await client.updateConfig({
            ...rest,
            ...updates,
        });
    };

    /**
     * Close the page
     */
    const close = async (args) => {
        history.push("../setup");
    };

    /**
     * Render the configuration page
     */
    return (
        <Page>
            <Page.Header title="General" closable onClose={close} />
            <Page.Body maxWidth={1000}>
                <Databases
                    config={config.search}
                    onChange={(search) => {
                        update({ search });
                    }}
                />
                <CustomDatabases
                    customDatabases={config.search.customDatabases}
                    onChange={(customDatabases) => {
                        return update({
                            search: {
                                ...config.search,
                                customDatabases,
                            },
                        });
                    }}
                />
                <Style
                    config={config.reference}
                    onChange={(reference) => {
                        update({ reference });
                    }}
                />
                <Tags
                    list={config.contentTags}
                    title="Content Tags"
                    onChange={(tags) => {
                        update({ contentTags: tags });
                    }}
                />
                <Tags
                    list={config.articleTags}
                    title="Article Tags"
                    onChange={(tags) => {
                        update({ articleTags: tags });
                    }}
                />
                <Tags
                    list={config.documentTags}
                    title="Document Tags"
                    onChange={(tags) => {
                        update({ documentTags: tags });
                    }}
                />
            </Page.Body>
        </Page>
    );
});

export default General;
