/**
 * Triggers a file containing the specified content to be downloaded in the user's browser.
 * @param {string} filename - The name of the file when downloaded.
 * @param {string} content - The complete contents of the file.
 */
export function triggerDownload(filename, content) {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

/**
 * Triggers a file download
 * @param {string} id - The id of the file to be downloaded.
 */
export function download(id) {
    // Create a link to download
    const link = document.createElement("a");
    link.href = `/api/file/${id}`;

    link.setAttribute("target", "_blank");
    link.setAttribute("download", "");

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export function downloadWithHighlights(id) {
    // Create a link to download
    const link = document.createElement("a");
    link.href = `/api/file/${id}/highlights`;

    link.setAttribute("target", "_blank");
    link.setAttribute("download", "");

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export default {
    download,
    triggerDownload,
    downloadWithHighlights,
};
