import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { useParams } from "react-router-dom";

import {
    UndoOutlined,
    DeleteOutlined,
    CloudDownloadOutlined,
    EllipsisOutlined,
    EditOutlined,
    MessageOutlined,
    DeploymentUnitOutlined,
    FileDoneOutlined,
    RestOutlined,
} from "@ant-design/icons";
import { Button, Menu, Dropdown } from "antd";

import UploadMenuItem from "@app/components/form/upload/menu-item";
import confirm from "@app/components/confirm";
import file from "@app/lib/file";
import { Review, SearchType } from "@app/constants";
import EditModal from "./edit/article";
import Note from "./note";

import "./style/file-menu.scoped.scss";

const ArticleMenu = observer(
    ({
        article = {},
        onUpload,
        onRemove,
        onChange,
        switchArticleType = false,
        onChangeType,
        onResetStatus,
        stage,
        onRemoveArticle,
    }) => {
        const { type } = useParams();
        const l1Excluded = article?.review?.l1?.status === Review.FAIL;
        const l2Excluded = article?.review?.l2?.status === Review.FAIL;

        const crossChannelArticle = article.crossChannel.used;
        const canSwitchType =
            (l1Excluded || l2Excluded) && !crossChannelArticle && switchArticleType;
        const hasNote = !!article.note;
        const enableResetStatus = !!article.review?.[stage]?.status;
        const enableArticleDelete = article.moved && article.originalSearch;

        var state = useLocalStore(() => ({
            editArticle: false,
            addNote: false,
        }));

        const remove = async () => {
            let review = article.review?.l2 || {};
            let started = review.started || review.complete;

            const message = started
                ? "The L2 review process for this article has already started. Are you sure that you want to remove the full text for this article?"
                : "Are you sure that you want to remove the full text for this article?";

            const proceed = await confirm(message);
            if (proceed) {
                onRemove();
            }
        };

        const onAction = (action) => {
            if (action.key === "remove") {
                remove();
            } else if (action.key === "download") {
                file.download(article.file);
            } else if (action.key === "download-with-highlights") {
                file.downloadWithHighlights(article.file);
            } else if (action.key === "editArticle") {
                state.editArticle = true;
            } else if (action.key === "addNote") {
                state.addNote = true;
            } else if (action.key === "addToSota") {
                onChangeType && onChangeType(SearchType.SOTA);
            } else if (action.key === "addToSLR") {
                onChangeType && onChangeType(SearchType.SLR);
            } else if (action.key === "resetStatus") {
                onResetStatus && onResetStatus();
            } else if (action.key === "deleteArticle") {
                onRemoveArticle && onRemoveArticle();
            }
        };

        /**
         * Prepare the actions menu
         */
        const actions = (
            <Menu onClick={onAction} className="menu">
                <Menu.ItemGroup title="Full Text">
                    {!article.file && (
                        <Menu.Item key="upload">
                            <UploadMenuItem
                                accept=".pdf,application/pdf"
                                onChange={(data) => {
                                    onUpload(data);
                                }}
                            >
                                Upload
                            </UploadMenuItem>
                        </Menu.Item>
                    )}

                    {article.file && (
                        <Menu.Item key="download" icon={<CloudDownloadOutlined />}>
                            Download
                        </Menu.Item>
                    )}

                    {article.file && (
                        <Menu.Item key="download-with-highlights" icon={<CloudDownloadOutlined />}>
                            Download + highlights
                        </Menu.Item>
                    )}

                    {article.file && (
                        <Menu.Item key="remove" icon={<DeleteOutlined />}>
                            Remove
                        </Menu.Item>
                    )}
                </Menu.ItemGroup>

                <Menu.ItemGroup title="Action">
                    <Menu.Item key="editArticle" icon={<EditOutlined />}>
                        Edit Details
                    </Menu.Item>

                    {!hasNote && (
                        <Menu.Item key="addNote" icon={<MessageOutlined />}>
                            Add a Note
                        </Menu.Item>
                    )}

                    {canSwitchType && type === SearchType.SLR && (
                        <Menu.Item key="addToSota" icon={<DeploymentUnitOutlined />}>
                            Use in SoTA
                        </Menu.Item>
                    )}

                    {canSwitchType && type === SearchType.SOTA && (
                        <Menu.Item key="addToSLR" icon={<FileDoneOutlined />}>
                            Use in DUE
                        </Menu.Item>
                    )}

                    {enableResetStatus && (
                        <Menu.Item key="resetStatus" icon={<UndoOutlined />}>
                            Reset Status
                        </Menu.Item>
                    )}

                    {enableArticleDelete && (
                        <Menu.Item key="deleteArticle" icon={<RestOutlined />}>
                            Remove Article
                        </Menu.Item>
                    )}
                </Menu.ItemGroup>
            </Menu>
        );

        return (
            <>
                <Dropdown overlay={actions} trigger="click" placement="bottomRight">
                    <Button className="drop-down-button" icon={<EllipsisOutlined />}></Button>
                </Dropdown>
                {state.editArticle && (
                    <EditModal
                        article={article}
                        onChange={(data) => {
                            onChange(data);
                            state.editArticle = false;
                        }}
                        onCancel={() => {
                            state.editArticle = false;
                        }}
                    />
                )}

                {state.addNote && (
                    <Note
                        className="group"
                        text={article.note}
                        add={state.addNote}
                        onChange={(note) => {
                            onChange({ note });
                            state.addNote = false;
                        }}
                        onCancel={() => {
                            state.addNote = false;
                        }}
                    />
                )}
            </>
        );
    },
);

export default ArticleMenu;
