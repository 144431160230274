import React, { useCallback, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";

import EditModal from "@app/components/dictionary/edit";
import Picker from "@app/components/dictionary/picker";

import listStore from "@app/state/store/report/dictionary/list";

const Content = observer(({ editor, dictionary, templateMode, documentId }) => {
    const state = useLocalStore(() => ({
        showPicker: false,
        showEditModal: false,
        entry: undefined,
    }));

    const onShowPicker = useCallback(() => {
        state.showPicker = true;
    });

    const onEditEntry = useCallback((entry) => {
        state.entry = entry;
        state.showEditModal = true;
    });

    const onAddEntry = useCallback((entry) => {
        state.entry = entry;
        state.showEditModal = true;
    });

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("dictionaryPicker", onShowPicker);
        editor.on("dictionaryEdit", onEditEntry);
        editor.on("dictionaryCreate", (name) => onAddEntry({ name }));

        return () => {
            editor.off("dictionaryPicker", onShowPicker);
            editor.off("dictionaryEdit", onEditEntry);
            editor.off("dictionaryCreate", onAddEntry);
        };
    }, [editor]);

    /**
     * Close the dictionary picker
     */
    const closePicker = () => {
        state.showPicker = false;
        editor.commands.focus();
    };

    /**
     * Pick a dictionary entry to add to the content
     */
    const pick = (entry) => {
        editor.chain().focus().addDictionaryEntry({ key: entry.name }).run();
        closePicker();
    };

    /**
     * Save an entry
     */
    const save = async (entry) => {
        if (!listStore.validateSave(entry)) {
            return;
        }

        if (templateMode) {
            await dictionary.saveEntryForTemplate(documentId, {
                ...entry,
                documentId,
                isTemplateValue: true,
            });
        } else {
            await dictionary.save(entry);
        }

        state.entry = undefined;
        state.showEditModal = false;
        state.showPicker = false;

        // if we create a new entry replace the selected text with it
        if (!entry._id) {
            editor.chain().focus().deleteSelection().addDictionaryEntry({ key: entry.name }).run();
        }
    };

    const onAddEntryInPicker = useCallback((entry) => {
        if (!templateMode) {
            state.entry = entry;
            state.showEditModal = true;
            state.showPicker = false;
        } else {
            save(entry);
        }
    });

    return (
        <>
            {state.showPicker && (
                <Picker
                    onClose={closePicker}
                    onPick={pick}
                    dictionary={dictionary}
                    onAddEntry={onAddEntryInPicker}
                />
            )}

            {state.showEditModal && (
                <EditModal
                    entry={state.entry}
                    onChange={save}
                    onCancel={() => {
                        state.entry = null;
                        state.showEditModal = false;
                    }}
                />
            )}
        </>
    );
});

export default Content;
