import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import TextInput from "./text-input";
import Modal from "@app/components/modal";
import notify from "@app/components/notify";

const EditModal = observer(({ entry = {}, onChange, onCancel }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            name: entry.name,
            text: entry.text,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, entry]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the value
     */
    const save = (data) => {
        const result = { _id: entry._id };
        result.name = (data.name || "").trim();
        result.text = (data.text || "").trim();

        onChange && onChange(result);
    };

    return (
        <Modal
            title={entry._id ? "Edit dictionary entry" : "Add dictionary entry"}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={800}
            wrapClassName="edit-dictionary-modal"
        >
            <div className="edit-dictionary">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please enter the name",
                            },
                        ]}
                    >
                        <Input readOnly={!!entry._id} />
                    </Form.Item>

                    <Form.Item
                        label="Text"
                        name="text"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please enter the text",
                            },
                        ]}
                    >
                        <TextInput
                            isBlock={false}
                            boundParent={".edit-dictionary-modal .ant-modal"}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default EditModal;
