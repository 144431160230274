import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "@app/components/loader/content";
import { SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";

import Page from "@app/components/page/page";
import Edit from "@app/components/setup/report-documents/structure/edit";
import { createState } from "@app/components/setup/report-documents/structure/context";
import notify from "@app/components/notify";

import documentsStore from "@app/state/store/report-document/report-documents";
import projectStore from "@app/state/store/projects/details";

const EditStructure = observer(() => {
    const tocState = createState();
    const history = useHistory();
    const { id, documentId } = useParams();
    const reportDocument = documentsStore.reportDocument;
    const sections = documentsStore.reportDocumentSections;
    const loaded = reportDocument && sections;

    useEffect(() => {
        if (id && documentId) {
            documentsStore.setProjectId(id);
            documentsStore.load(documentId);
        }
    }, [id, documentId]);

    const close = () => {
        history.push(`/r/${id}/setup/report-documents/${documentId}`);
    };

    const save = async (data) => {
        const { treeData } = tocState;

        const valid = tocState.validateTree(treeData);
        if (!valid) {
            notify.error(
                "There are issues with some of the sections. " +
                    "Please correct them before you can continue.",
            );

            tocState.setTreeData([...treeData]);
            return;
        }

        const tree = tocState.trimNodes(treeData);
        tocState.setTreeData([...tree]);

        const toc = tocState.convertTreeToFlatData(tree, {
            reportDocumentId: documentId,
            project: documentsStore.projectId,
            client: projectStore?.data?.client?._id,
        });

        await documentsStore.saveReportDocumentSections(documentId, toc);
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>{reportDocument?.name ?? "Loading..."}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1000}>
                {loaded && (
                    <Edit reportDocument={reportDocument} sections={sections} state={tocState} />
                )}

                <Loader loading={!loaded} />
            </Page.Body>
            <Page.Footer>
                {loaded && (
                    <Page.Footer.Right>
                        <Button icon={<SaveOutlined />} type="primary" onClick={save}>
                            Save
                        </Button>
                        <Button onClick={close}>Close</Button>
                    </Page.Footer.Right>
                )}
            </Page.Footer>
        </Page>
    );
});

export default EditStructure;
