import { observer, useLocalStore } from "mobx-react";
import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { default as qs } from "qs";
import _ from "lodash";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import classes from "classnames";
import { Button, Spin } from "antd";
import { ReportTable } from "./report-table";
import http from "@app/lib/http";
import { EyeOutlined, DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import confirm from "@app/components/confirm/index";
import CaptionModal from "@app/components/caption/modal";
import { parseCaptionText } from "@app/components/report-document/editor/modules/diff/diff-utility";
import { ReportTypes } from "@app/constants";

import documentStore from "@app/state/store/report-document/document";
import versionStore from "@app/state/store/report-document/version";

const ReportView = ({
    report,
    project,
    dataType,
    loadRestButton,
    updateAttributes,
    headersWidth,
    readonly,
}) => {
    const ReportImageView = useMemo(() => {
        const time = Date.now();
        let src;
        if (report?.reportType === "prisma-flow") {
            src = `/api/project/${project}/reports/prisma/${report.type}?${time}`;
        }

        return <img src={src} style={{ width: "100%" }} />;
    }, [report?.configId]);

    const ReportTableView = useMemo(() => {
        const noRows = !(report?.rows?.length > 0);
        if (noRows) {
            return null;
        }

        const updateHeadersWidth = (headersWidth) => {
            updateAttributes({ headersWidth });
        };

        return (
            <div className="report-table">
                <ReportTable
                    config={{
                        width: "100%",
                        fontSize: report?.fontSize,
                        layout: report?.layout,
                        type: report?.reportType,
                    }}
                    rows={report?.rows}
                    columns={report.columns}
                    resizeable={!readonly}
                    headersWidth={headersWidth}
                    updateHeadersWidth={updateHeadersWidth}
                />
                {loadRestButton && loadRestButton}
            </div>
        );
    }, [report?.configId]);

    if (!report) {
        return null;
    }

    if (!dataType) {
        if (report?.reportType === "prisma-flow") {
            dataType = "image";
        } else {
            dataType = "table";
        }
    }

    if ("image" === dataType) {
        return ReportImageView;
    } else if ("table" === dataType) {
        return ReportTableView;
    }
};

const EditableReport = observer(
    ({ report, caption, onDelete, onPreview, selected, dataType, reportView }) => {
        const { project } = useParams();
        const history = useHistory();

        if (!report) {
            return null;
        }

        const backUrl = location.pathname;
        const settingsUrl = `/r/${project}/setup/reports/${report.type}/${report.configId}`;

        return (
            <div
                className={classes({
                    selected,
                })}
            >
                <div className={`report-header ${dataType}-header`}>
                    {dataType === "table" && <div className="tableCaption">{caption}</div>}
                    <div className="report-actions">
                        <EyeOutlined onClick={onPreview} />
                        <SettingOutlined
                            onClick={() => history.push(`${settingsUrl}?referrer=${backUrl}`)}
                        />
                        <DeleteOutlined
                            onClick={async () => {
                                const proceed = await confirm(
                                    "Are you sure you want to remove this report?",
                                );
                                if (proceed) {
                                    onDelete();
                                }
                            }}
                        />
                    </div>
                </div>
                {reportView}
                {dataType === "image" && <div className="imageCaption">{caption}</div>}
            </div>
        );
    },
);

const ErrorReport = observer(({ error, onDelete, readonly }) => {
    let message;

    if (error.includes("Missing report")) {
        message = "This report is missing.";
    }

    return (
        <div className="report-header">
            <h1 className="report-title error-title">{message}</h1>
            {!readonly && (
                <div className="report-actions">
                    <DeleteOutlined
                        onClick={async () => {
                            const proceed = await confirm(
                                "Are you sure you want to remove this report?",
                            );
                            if (proceed) {
                                onDelete();
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
});

const ReadOnlyReport = observer(({ caption, dataType, reportView }) => {
    return (
        <div>
            {dataType === "table" && <div className="tableCaption">{caption}</div>}
            {reportView}
            {dataType === "image" && <div className="imageCaption">{caption}</div>}
        </div>
    );
});

const Report = observer(
    ({
        id,
        nodeId,
        type,
        dataType,
        caption,
        onEditCaption,
        readonly,
        selected,
        deleteNode,
        previewReport,
        headersWidth,
        updateAttributes,
    }) => {
        const { project } = useParams();
        const [report, setReport] = React.useState(null);
        const [loading, setLoading] = React.useState(false);

        const load = async () => {
            setLoading(true);
            if (id) {
                const rows = documentStore.reports[nodeId]?.rows;

                const { data } = await http.get(
                    `/project/${project}/reports/new/${type}/${id}?rows=${rows}`,
                );
                setReport(data);
            }

            setLoading(false);
        };

        useEffect(() => {
            load();
        }, []);

        const LoadRestButton = () => {
            const storeReport = documentStore.reports[nodeId];
            if (!storeReport) {
                return null;
            }

            const hasMoreRows = storeReport.rows < report.totalRows;
            const show =
                [
                    ReportTypes.L2_REVIEW_SUMMARY,
                    ReportTypes.ARTICLE_DATA_SHEET,
                    ReportTypes.APPENDIX,
                    ReportTypes.CUSTOM,
                ].includes(report?.reportType) && hasMoreRows;

            const updateRows = () => {
                if (storeReport && hasMoreRows) {
                    storeReport.rows = report.totalRows;

                    load();
                }
            };

            return (
                show && (
                    <div className="loadRestButton">
                        <Button type="dashed" onClick={updateRows}>
                            Load the rest
                        </Button>
                    </div>
                )
            );
        };

        if (loading) {
            return (
                <div className="spinner text-center">
                    <Spin size="large" tip="Loading..." />
                </div>
            );
        }

        if (report?.error) {
            updateAttributes({ error: report.error });
            return <ErrorReport error={report.error} onDelete={deleteNode} readonly={readonly} />;
        }

        const reportView = (
            <ReportView
                report={report}
                project={project}
                dataType={dataType}
                loadRestButton={<LoadRestButton />}
                readonly={readonly}
                headersWidth={headersWidth}
                updateAttributes={updateAttributes}
            />
        );

        if (readonly) {
            return <ReadOnlyReport caption={caption} dataType={dataType} reportView={reportView} />;
        }

        return (
            <EditableReport
                report={report}
                caption={caption}
                onEditCaption={onEditCaption}
                onDelete={deleteNode}
                onPreview={() => previewReport(report)}
                selected={selected}
                dataType={dataType}
                reportView={reportView}
            />
        );
    },
);

export default observer(({ node, extension, editor, selected, deleteNode, updateAttributes }) => {
    const { caption, "data-diff-node": change, "data-diff-id": diffId, dataType } =
        node.attrs || {};
    const versionId = versionStore.versionId;
    let figureData;
    if (dataType === "image") {
        figureData = documentStore.figureDetails(node.attrs.id);
    } else if (dataType === "table") {
        figureData = documentStore.tableDetails(node.attrs.id);
    }

    const displayedCaption = versionId ? parseCaptionText(caption) : figureData?.fullCaption;
    const hasCaption = !_.isEmpty(caption);
    const location = useLocation();
    const ref = useRef(null);

    const state = useLocalStore(() => ({
        isEditingCaption: false,
    }));

    const onEditCaption = useCallback(({ node: inputNode }) => {
        if (inputNode?.attrs?.id === node.attrs.id) {
            editCaption();
        }
    });

    const closeEditingCaption = () => {
        state.isEditingCaption = false;
    };

    const editCaption = () => {
        state.isEditingCaption = true;
    };

    const updateCaption = ({ caption }) => {
        closeEditingCaption();
        updateAttributes({ caption });

        if ("table" === node.attrs.dataType) {
            documentStore.updateTableCaption(node.attrs.id, caption);
        } else if ("image" === node.attrs.dataType) {
            documentStore.updateFigureCaption(node.attrs.id, caption);
        }
    };

    useEffect(() => {
        if (ref && ref.current) {
            const params = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (params.node === node.attrs.id) {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [location]);

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("editReportCaption", onEditCaption);
        return () => {
            editor.off("editReportCaption", onEditCaption);
        };
    }, [editor]);

    const Caption = hasCaption && <div dangerouslySetInnerHTML={{ __html: displayedCaption }} />;

    return (
        <NodeViewWrapper ref={ref} className="report-node-view">
            <NodeViewContent>
                <div
                    className={classes({
                        inserted: change === "ins",
                        deleted: change === "del",
                    })}
                    data-diff-id={diffId}
                >
                    <Report
                        id={node.attrs.report}
                        nodeId={node.attrs.id}
                        type={node.attrs.type}
                        dataType={node.attrs.dataType}
                        caption={Caption}
                        onEditCaption={editCaption}
                        readonly={extension.options.readonly}
                        selected={selected}
                        deleteNode={deleteNode}
                        previewReport={editor.commands.previewReport}
                        headersWidth={node.attrs.headersWidth}
                        updateAttributes={updateAttributes}
                    />
                </div>

                <CaptionModal
                    title={"Edit Report Caption"}
                    caption={node.attrs.caption}
                    open={state.isEditingCaption}
                    onSubmit={updateCaption}
                    onCancel={closeEditingCaption}
                />
            </NodeViewContent>
        </NodeViewWrapper>
    );
});
