import { isInTable, selectionCell } from "@tiptap/pm/tables";

export function isInFootnote({ editor }) {
    const { state } = editor;
    const { selection } = state;
    const { $from } = selection;

    if (!isInTable(state)) {
        return false;
    }

    const cell = selectionCell(state);

    if (!cell) {
        return false;
    }

    let depth = $from.depth;
    let row;

    while (depth > 0) {
        const node = $from.node(depth);
        if (node.type.name === "tableRow") {
            row = node;
            break;
        }
        depth--;
    }

    if (row && row.attrs.isFootnote) {
        return true;
    }

    return false;
}

export default isInFootnote;
