import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";
import { Link } from "react-router-dom";
import { SearchType } from "@app/constants";
import { ExportMenu } from "./output/dynamicReport/export";

import projectState from "@app/state/store/projects/setup";

import "./output/style/common.scoped.scss";

const ReportList = observer(() => {
    const { project, type } = useParams();
    const title = type === SearchType.SLR ? "DUE" : "SoTA";

    useEffect(() => {
        projectState.load(project);
    }, [project]);

    const reportIds = projectState?.config?.reports?.[type]
        .filter((config) => config.reportType !== "prisma-flow")
        .map((reportConfig) => reportConfig._id);

    return (
        <Page className="reports">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>{`${title} Reports`}</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <ExportMenu project={project} type={type} reportIds={reportIds} />
                </Page.Header.Right>
            </Page.Header>
            <Page.Body maxWidth={800}>
                <div className="section">
                    <div className="header">{`Reports`}</div>
                    <div className="body">
                        {projectState?.config?.reports?.[type].map((reportConfig) => (
                            <Link
                                key={reportConfig._id}
                                to={`${type}/${reportConfig._id}`}
                                className="link"
                            >
                                {reportConfig.reportName}
                            </Link>
                        ))}
                    </div>
                </div>
            </Page.Body>
        </Page>
    );
});

export default ReportList;
