import { action } from "mobx";
import { cloneDeep } from "lodash";
import BaseStore from "./base";

/**
 * Recursively crawls the given object tree and removes any object properties that match the supplied key.
 * Warning: This function will mutate the supplied object.
 * @param obj - The object to crawl
 * @param key - The key to remove everywhere in the object
 */
function removeKeyDeep(obj, key, saveOldId = false) {
    for (const prop in obj) {
        if (prop === key) {
            if (saveOldId) {
                obj.oldId = obj._id;
            }
            delete obj[prop];
        } else if (typeof obj[prop] === "object") {
            removeKeyDeep(obj[prop], key, saveOldId);
        }
    }
}

/**
 * Global state to hold copied config values for pasting to other config section.
 */
export class ConfigClipboard extends BaseStore {
    /**
     * observable store data
     */
    observable() {
        return {
            tags: [],
            keywords: [],
            excludeReasons: [],
            questions: [],
            additionalData: [],
            reports: [],
            reportDocument: {},
        };
    }

    @action
    copy(path, value = [], saveOldId = false) {
        this[path] = cloneDeep(value).map((entry) => {
            removeKeyDeep(entry, "_id", saveOldId);
            return entry;
        });
    }

    @action
    paste(path, isDuplicate) {
        const value = this[path] || [];

        this[path] = [];

        if (isDuplicate) {
            return value.filter((entry) => !isDuplicate(entry));
        }

        return value;
    }

    @action
    copyReportDocument(copy) {
        this.reportDocument = copy;
    }
}

const configClipboard = new ConfigClipboard();
export default configClipboard;
