import React from "react";
import { observer } from "mobx-react";
import { useHistory, Link } from "react-router-dom";
import { Button } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Role } from "@app/constants";
import SearchSummary from "@app/components/overview/search";
import ProjectSummary from "@app/components/overview/summary";
import PendingActions from "@app/components/overview/pending";
import { ProjectStatus } from "@app/constants";

import report from "@app/state/store/report";
import session from "@app/state/store/session";
import overview from "@app/state/store/overview";

import "./style/overview.scoped.scss";

const SearchOverview = observer((props) => {
    const history = useHistory();
    const project = report.project;
    const draft = report.project?.status === ProjectStatus.DRAFT ? true : false; //??

    const withSota = report.config?.sota?.workflowEnabled;
    const withSourceDocuments = report.config?.sourceDocuments.enabled;

    React.useEffect(() => {
        if (!draft) {
            overview.stats();
        }
    }, [draft, project]);

    // wait until the project state is initialized
    if (!project || project.deleted) {
        return;
    }

    const configure = () => {
        history.push(`./setup`);
    };

    let canConfigure = false;
    if (project.userRoles.includes(Role.PROJECT_MANAGER) || session.can("system.manage")) {
        canConfigure = true;
    }

    const renderConfigurationSteps = () => {
        if (canConfigure) {
            return (
                <>
                    <p>
                        Please use the <b>Configure</b> button below to complete the project
                        configuration.
                    </p>

                    <Link to={`/r/${project._id}/setup`}>
                        <Button onClick={configure} type="primary">
                            Configure
                        </Button>
                    </Link>
                </>
            );
        }

        return (
            <p>
                Please contact the Project Manager to have them complete the project configuration.
            </p>
        );
    };

    return (
        <>
            {draft ? (
                <div className="configure-message">
                    <div className="icon">
                        <WarningOutlined />
                    </div>

                    <p>
                        This project is still in draft state. You need to complete and publish the
                        project configuration before the project becomes active.{" "}
                    </p>

                    {renderConfigurationSteps()}
                </div>
            ) : (
                <>
                    <div className="grid">
                        <div className="row">
                            <div className="cell">
                                <SearchSummary
                                    results={overview.data?.search?.slr?.sources}
                                    title="Search Results (DUE)"
                                />
                            </div>

                            {withSota && (
                                <div className="cell">
                                    <SearchSummary
                                        results={overview.data?.search?.sota?.sources}
                                        title="Search Results (SoTA)"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="cell">
                                <PendingActions
                                    summary={overview.data}
                                    withSota={withSota}
                                    withSourceDocuments={withSourceDocuments}
                                />
                            </div>

                            <div className="cell">
                                <ProjectSummary
                                    summary={overview.data}
                                    withSota={withSota}
                                    withSourceDocuments={withSourceDocuments}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
});

export default SearchOverview;
