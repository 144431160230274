import React from "react";
import { Button } from "antd";

import symbols from "./symbols";
import "./style.scss";

const SymbolsMenu = ({ onInsert }) => {
    return (
        <div className="symbol-container" style={{ gridTemplateColumns: "repeat(6, 1fr)" }}>
            {symbols.map((item, index) => (
                <Button className="item" key={index} onClick={() => onInsert && onInsert(item)}>
                    {item.value}
                </Button>
            ))}
        </div>
    );
};

export default SymbolsMenu;
