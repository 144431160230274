import { PageSize } from "@app/constants";
import { scrollTo } from "@app/util/scroll";
import { DOMSerializer } from "@tiptap/pm/model";
import { CellSelection, TableMap } from "@tiptap/pm/tables";

import { ReportDocumentSection } from "@app/constants";

import dictionaryStore from "@app/state/store/report/dictionary/pick";
import documentStore from "@app/state/store/report-document/document";

import TableStepper from "./modules/table/utils/table-stepper";
import { selectedRect } from "./modules/table/utils/merge-cells";

export function getPageWidth(type, options = {}) {
    return PageSize["A4P"];
}

export function scrollToSelection(commentId) {
    scrollTo(`[data-icon-${commentId}]`, { position: "center" });
}

export function scrollToComment(commentId) {
    scrollTo(`[data-id="${commentId}"]`);
}

export function scrollToSection(sectionId) {
    scrollTo(`[data-section-id="${sectionId}"]`);
}

const getHTMLFromNode = (node, editor) => {
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const fragment = serializer.serializeFragment(node.content);
    const div = document.createElement("div");
    div.appendChild(fragment);
    return div.innerHTML; // This gives you the HTML content
};

const getPlainTextFromHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || ""; // Extracts plain text from HTML
};

export function serializeSelectionToPlainText(editor, from, to) {
    return editor.state.doc.textBetween(from, to, "\n", (leafNode) => {
        const nodeName = leafNode.type.name;
        let text = nodeName;

        switch (nodeName) {
            case "image": {
                const figureData = documentStore.figureDetails(leafNode.attrs.id);
                text = `[Image: ${figureData?.fullCaption ? `- ${figureData.fullCaption}` : ""}]`;
                break;
            }
            case "dictionary": {
                const entry = dictionaryStore.entryMap[leafNode.attrs.key];
                text = entry ? entry.text : `<${leafNode.attrs.key}>`;
                break;
            }
            case "citation": {
                const citation = documentStore.refMap[leafNode.attrs.id];
                text = citation?.isValid ? `[${citation.index}]` : "Invalid reference";
                break;
            }
            case "cross-reference": {
                const crossReferencesMap =
                    editor.storage["cross-reference"].store.crossReferencesMap;
                const crossReference = crossReferencesMap[leafNode.attrs.id];
                text = !crossReference ? `\u26A0 Missing Reference` : crossReference.text;
                break;
            }
            case "abbreviation": {
                text = leafNode.attrs.key;
                break;
            }
            case "report": {
                const { dataType, id } = leafNode.attrs;
                let figureData;

                if (dataType === "image") {
                    figureData = documentStore.figureDetails(id);
                } else if (dataType === "table") {
                    figureData = documentStore.tableDetails(id);
                }

                text = `[Report: ${figureData?.fullCaption}]`;
                break;
            }
            case ReportDocumentSection.CUSTOM_OBJECT.NODE_NAME: {
                const { title, type } = leafNode.attrs.metadata ?? {};

                const typeToName = (t) => {
                    if (t === ReportDocumentSection.CUSTOM_OBJECT.TYPES.TOC) {
                        return "Table of Contents";
                    }

                    if (t === ReportDocumentSection.CUSTOM_OBJECT.TYPES.ABBREVIATIONS) {
                        return "List of Abbreviations";
                    }

                    if (t === ReportDocumentSection.CUSTOM_OBJECT.TYPES.REFERENCES) {
                        return "List of References";
                    }

                    return t;
                };

                text = `[Custom Object: ${title || typeToName(type)}]`;

                break;
            }
            default: {
                const htmlContent = getHTMLFromNode(leafNode, editor);
                text = getPlainTextFromHTML(htmlContent);
            }
        }

        return text;
    });
}

export const FORMATTING_TYPES = {
    BOLD: "bold",
    ITALIC: "italic",
    HIGHLIGHT: "highlight",
    UNDERLINE: "underline",
    SUBSCRIPT: "subscript",
    SUPERSCRIPT: "superscript",
    BULLET_LIST: "bulletList",
    ORDERED_LIST: "orderedList",
    TEXT_ALIGN_LEFT: "textAlignLeft",
    TEXT_ALIGN_RIGHT: "textAlignRight",
    TEXT_ALIGN_CENTER: "textAlignCenter",
    TEXT_ALIGN_JUSTIFY: "textAlignJustify",
    VERTICAL_ALIGN_TOP: "top",
    VERTICAL_ALIGN_MIDDLE: "middle",
    VERTICAL_ALIGN_BOTTOM: "bottom",
};

export const validElements = ["tableCell", "tableHeader", "table", "tableRow"];

const applyListFormatting = (editor, listType) => {
    const { selection } = editor.state;
    const anchorCellPos = selection.$anchorCell.pos;
    const headCellPos = selection.$headCell.pos;

    const { left, bottom, right, top } = selectedRect(editor.state);

    const isInRect = ({ row, column }) => {
        return row >= top && row < bottom && column >= left && column < right;
    };

    const table = selection.$anchorCell.node(-1);
    const tableMap = TableMap.get(table);
    const tableStart = selection.$anchorCell.start(-1);

    const anchorRow = tableMap.findCell(anchorCellPos - tableStart).top;
    const anchorCol = tableMap.findCell(anchorCellPos - tableStart).left;
    const headRow = tableMap.findCell(headCellPos - tableStart).top;
    const headCol = tableMap.findCell(headCellPos - tableStart).left;

    // Convert the table node to JSON
    const tableJSON = table.toJSON();

    const slots = [
        ...new TableStepper(tableJSON, {
            tableMode: "JSON",
        }),
    ];

    const isListNode = (node) =>
        node.type &&
        [FORMATTING_TYPES.BULLET_LIST, FORMATTING_TYPES.ORDERED_LIST].includes(node.type);

    const cellHasList = (cell) => cell.content && isListNode(cell.content[0]);
    const hasSameListType = (cell) => cell.content && cell.content[0].type === listType;

    let allCellsHaveList = true;
    let allCellsHaveSameListType = true;

    for (let slot of slots) {
        if (isInRect(slot)) {
            if (!hasSameListType(slot.cell)) {
                allCellsHaveSameListType = false;
            }

            if (!cellHasList(slot.cell)) {
                allCellsHaveList = false;
                break; // Exit the loop early since not all cells have a list
            }
        }
    }

    const unwrapList = (cell) => {
        if (!cell.content?.length) {
            return;
        }

        const newContent = [];
        cell.content.forEach((node) => {
            if (isListNode(node)) {
                node.content.forEach((item) => {
                    if (item.type === "listItem") {
                        newContent.push(...item.content);
                    } else {
                        newContent.push(item);
                    }
                });
            } else {
                newContent.push(node);
            }
        });
        cell.content = newContent;
    };

    const wrapInList = (cell, type) => {
        const listItems = [];

        if (!cell.content?.length) {
            return;
        }

        if (cell.content.length === 1 && isListNode(cell.content[0])) {
            return;
        }

        if (!cell.content.some((node) => isListNode(node))) {
            cell.content = [
                {
                    type: type,
                    content: cell.content.map((paragraph) => ({
                        type: "listItem",
                        content: [paragraph],
                    })),
                },
            ];
            return;
        }

        cell.content.forEach((node) => {
            if (!isListNode(node)) {
                listItems.push({
                    type: "listItem",
                    content: [node],
                });
                return;
            }

            listItems[listItems.length - 1].content.push(node);
        });

        cell.content = [{ type: type, content: listItems }];
    };

    const updateCellContent = (cell) => {
        if (!cell) {
            return;
        }

        if (!cell.content) {
            return;
        }

        if (allCellsHaveList && allCellsHaveSameListType) {
            unwrapList(cell);
        } else {
            unwrapList(cell);
            wrapInList(cell, listType);
        }
    };

    // create a list of cells and add their row/col property (keeping the existing cell properties)
    // the row/col property is an array indicating to which rows/columns the cell belongs

    if (!slots) {
        return false;
    }

    slots.forEach((slot) => {
        if (isInRect(slot)) {
            updateCellContent(slot.cell);
        }
    });

    const newTable = editor.schema.nodeFromJSON(tableJSON);
    let transaction = editor.state.tr.replaceWith(
        tableStart - 1,
        tableStart + table.nodeSize - 1,
        newTable,
    );

    editor.view.dispatch(transaction);

    const newState = editor.state;
    const newTableNode = newState.doc.nodeAt(tableStart - 1);

    if (!newTableNode || newTableNode.type.spec.tableRole !== "table") {
        console.error(
            "Updated node is not a table: ",
            newTableNode ? newTableNode.type.name : newTableNode,
        );
        return;
    }

    const newTableMap = TableMap.get(newTableNode);
    const newAnchorCellPos =
        newTableMap.map[anchorRow * newTableMap.width + anchorCol] + tableStart;
    const newHeadCellPos = newTableMap.map[headRow * newTableMap.width + headCol] + tableStart;

    const newDoc = newState.doc;
    const validAnchorCellPos = Math.min(Math.max(newAnchorCellPos, 0), newDoc.content.size);
    const validHeadCellPos = Math.min(Math.max(newHeadCellPos, 0), newDoc.content.size);

    if (validAnchorCellPos !== undefined && validHeadCellPos !== undefined) {
        const cellSelection = CellSelection.create(newDoc, validAnchorCellPos, validHeadCellPos);
        editor.view.dispatch(newState.tr.setSelection(cellSelection));
    }
};

const applyFormattingToCellSelection = (editor, type, option) => {
    const { selection } = editor.state;
    const anchorCellPos = selection.$anchorCell.pos;
    const headCellPos = selection.$headCell.pos;

    if (type !== FORMATTING_TYPES.BULLET_LIST && type !== FORMATTING_TYPES.ORDERED_LIST) {
        applyFormattingToTextSelection(editor, type, option);
        // Restore the original cell selection
        const newDoc = editor.state.doc;

        // Ensure the positions are within the valid range
        const validAnchorCellPos = Math.min(Math.max(anchorCellPos, 0), newDoc.content.size);
        const validHeadCellPos = Math.min(Math.max(headCellPos, 0), newDoc.content.size);

        if (validAnchorCellPos !== undefined && validHeadCellPos !== undefined) {
            const cellSelection = CellSelection.create(
                newDoc,
                validAnchorCellPos,
                validHeadCellPos,
            );

            editor.view.dispatch(editor.state.tr.setSelection(cellSelection));
        }
    } else {
        applyListFormatting(editor, type);
    }
};

const applyFormattingToTextSelection = (editor, type, option) => {
    switch (type) {
        case FORMATTING_TYPES.BOLD:
            editor.chain().focus().toggleBold().run();
            break;
        case FORMATTING_TYPES.ITALIC:
            editor.chain().focus().toggleItalic().run();
            break;
        case FORMATTING_TYPES.HIGHLIGHT:
            editor.chain().focus().toggleHighlight({ color: option.color }).run();
            documentStore.highlight = option.color;
            break;
        case FORMATTING_TYPES.UNDERLINE:
            editor.chain().focus().toggleUnderline().run();
            break;
        case FORMATTING_TYPES.SUBSCRIPT:
            editor.chain().focus().toggleSubscript().run();
            break;
        case FORMATTING_TYPES.SUPERSCRIPT:
            editor.chain().focus().toggleSuperscript().run();
            break;
        case FORMATTING_TYPES.BULLET_LIST:
            editor.chain().focus().toggleBulletList().run();
            break;
        case FORMATTING_TYPES.ORDERED_LIST:
            editor.chain().focus().toggleOrderedList().run();
            break;
        case FORMATTING_TYPES.TEXT_ALIGN_LEFT:
            editor.chain().focus().setTextAlign("left").run();
            break;
        case FORMATTING_TYPES.TEXT_ALIGN_RIGHT:
            editor.chain().focus().setTextAlign("right").run();
            break;
        case FORMATTING_TYPES.TEXT_ALIGN_CENTER:
            editor.chain().focus().setTextAlign("center").run();
            break;
        case FORMATTING_TYPES.TEXT_ALIGN_JUSTIFY:
            editor.chain().focus().setTextAlign("justify").run();
            break;
        default:
            break;
    }
};

export const applyFormatting = (editor, type, option = {}) => {
    const { selection } = editor.state;
    if (selection instanceof CellSelection) {
        applyFormattingToCellSelection(editor, type, option);
    } else {
        applyFormattingToTextSelection(editor, type, option);
    }
};

export const inLandscape = (editor) => {
    return editor.state.selection?.$head.path.some((node) => node?.type?.name === "landscape");
};

export const inList = (editor) => {
    return editor.state.selection?.$head.path.some((node) => node?.type?.name === "listItem");
};

export default {
    getPageWidth,
    scrollToSelection,
    scrollToComment,
    applyFormatting,
    FORMATTING_TYPES,
};
