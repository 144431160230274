import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Input, Select, DatePicker } from "antd";
import notify from "@app/components/notify";
import Page from "./page";

import project from "@app/state/store/projects/details";
import clients from "@app/state/store/clients/pick";
import moment from "moment";

const General = observer(() => {
    const clientId = project.data?.client?._id;
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const endDate = project.data?.endDate ? moment(project.data?.endDate) : undefined;

    useEffect(() => {
        form.setFieldsValue({
            ...project.data,
            client: clientId,
            endDate,
        });
        clients.load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, form]);

    /**
     * Save the project and redirect to setup page
     * @param {Object} data
     */
    const finish = async (data) => {
        data.deviceNames = data?.deviceNames?.split(",");
        data.manufacturerNames = data?.manufacturerNames?.split(",");
        data.endDate = data.endDate.format("YYYY-MM-DD");

        const success = await project.update(data);
        if (success) {
            history.push(`/r/${project.id}/setup`);
        }
    };

    /**
     * Submit the form
     */
    const submit = () => {
        form.submit();
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    const Client = observer(() => {
        const [configuration, setConfiguration] = useState([]);

        const getConfigurations = (id) => {
            form.setFieldsValue({ configuration: "" });
            let selected = clients.list.filter((item) => item._id === id);
            if (selected.length > 0 && selected[0].configurations?.length > 0) {
                setConfiguration(selected[0].configurations);
            } else {
                setConfiguration([]);
            }
        };

        if (id) {
            return (
                <>
                    <div className="data-entry">
                        <label>Client</label>
                        <span>{project.data.client.name}</span>
                    </div>
                    <div className="data-entry">
                        <label>Configuration</label>
                        <span>{project?.data?.config?.name ?? "Not Found"}</span>
                    </div>
                </>
            );
        }

        return (
            <>
                <Form.Item
                    label="Client"
                    name="client"
                    rules={[
                        {
                            required: true,
                            message: "Please pick a client",
                        },
                    ]}
                >
                    <Select onChange={(value) => getConfigurations(value)}>
                        {clients.list.map((client) => {
                            return (
                                <Select.Option key={client._id} value={client._id}>
                                    {client.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Configuration"
                    name="configuration"
                    rules={[
                        {
                            required: true,
                            message: "Please pick a configuration",
                        },
                    ]}
                >
                    <Select>
                        {configuration?.map((item) => {
                            return (
                                <Select.Option key={item._id} value={item._id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </>
        );
    });

    return (
        <Page onSave={submit}>
            <Form
                layout="vertical"
                initialValues={{
                    ...project.data,
                    client: clientId,
                    endDate,
                }}
                form={form}
                onFinish={finish}
                onFinishFailed={validationError}
            >
                <Form.Item
                    label="Project Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: "Please enter the project name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Client />

                <Form.Item label="Description" name="description">
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
                </Form.Item>

                <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[
                        {
                            required: true,
                            message: "Please pick an end date",
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} />
                </Form.Item>
            </Form>
        </Page>
    );
});

export default General;
