import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import {
    ContainerOutlined,
    DesktopOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    BuildOutlined,
    LeftOutlined,
    RightOutlined,
    SearchOutlined,
    TeamOutlined,
    BookOutlined,
    ReadOutlined,
    IdcardOutlined,
    ControlOutlined,
    DeploymentUnitOutlined,
    SettingOutlined,
    WarningOutlined,
    ProfileOutlined,
    HddOutlined,
    FormOutlined,
    FileZipOutlined,
    ExceptionOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import Menu from "./navigation/menu";
import state from "./navigation/state";

import report from "@app/state/store/report";
import session from "@app/state/store/session";
import featuresStore from "@app/state/store/features";

import logoName from "@app/assets/logo-name.svg";
import logoIcon from "@app/assets/logo-icon.svg";

import "./style/navigation.scoped.scss";
import { ProjectStatus, SearchType } from "@app/constants";

// main application links
const mainLinks = [
    { label: "Dashboard", link: "/", exact: true, icon: <DesktopOutlined /> },
    { label: "Projects", link: "/projects", icon: <ContainerOutlined /> },
    {
        label: "Clients",
        icon: <IdcardOutlined />,
        link: "/clients",
        when: "client.manage",
    },
    {
        label: "Users",
        icon: <TeamOutlined />,
        link: "/users",
        when: "system.manage",
    },
];

// Define sub sections for SLR and SoTA projects
const workflowLinks = (type) => [
    {
        label: "L1: Screening",
        link: `/${type}/l1`,
        icon: <FileSearchOutlined />,
        requireConfig: true,
        type,
    },
    {
        label: "L2: Review",
        link: `/${type}/l2`,
        icon: <FileDoneOutlined />,
        requireConfig: true,
        type,
    },
    {
        label: "Reports",
        link: `/reports/${type}`,
        icon: <FileSearchOutlined />,
        requireConfig: true,
        type,
    },
    {
        label: "Snippets",
        link: `/${type}/report/highlights`,
        icon: <BookOutlined />,
        requireConfig: true,
        type,
    },
];

const configLinks = ({ projectMenu = false, features } = {}) => {
    const menu = [
        {
            label: "Setup",
            link: ``,
            exact: true,
            icon: <ControlOutlined />,
        },
        {
            label: "General",
            link: `/general`,
            icon: <SettingOutlined />,
        },
        {
            label: "DUE",
            link: `/slr`,
            icon: <FileSearchOutlined />,
        },
        {
            label: "SoTA",
            link: `/sota`,
            icon: <DeploymentUnitOutlined />,
        },
    ];

    if (projectMenu) {
        menu.push({
            label: "Source Documents",
            link: `/source-documents`,
            icon: <ProfileOutlined />,
        });
        menu.push({
            label: "Risk Analysis",
            link: `/risk`,
            icon: <WarningOutlined />,
        });
        // insert team setup link to the second position of the project menu
        menu.splice(2, 0, {
            label: "Team",
            link: `/team`,
            icon: <TeamOutlined />,
        });
    }

    menu.push({
        label: "Literature Reports",
        link: `/reports`,
        icon: <BookOutlined />,
    });

    menu.push({
        label: "Report Documents",
        link: `/report-documents`,
        icon: <FileZipOutlined />,
    });

    return menu;
};

const projectLinks = (config, features) => {
    const draft = report?.project?.status === ProjectStatus.DRAFT;

    const isDeleted = report?.project?.deleted;

    if (isDeleted) {
        return [];
    }

    const projectMenu = [
        {
            label: "Overview",
            link: "/",
            exact: true,
            icon: <BuildOutlined />,
        },
    ];

    if (!draft) {
        projectMenu.push(
            { label: "Search", link: "/search", icon: <SearchOutlined /> },
            {
                label: "DUE",
                icon: <ReadOutlined />,
                children: workflowLinks(SearchType.SLR),
            },
        );

        // Only show SoTA process if enabled
        if (config?.sota?.enabled) {
            if (config?.sota?.workflowEnabled) {
                projectMenu.push({
                    label: "SoTA",
                    icon: <DeploymentUnitOutlined />,
                    children: workflowLinks(SearchType.SOTA),
                });
            } else {
                projectMenu.push({
                    label: "SoTA",
                    icon: <DeploymentUnitOutlined />,
                    children: [
                        {
                            label: "Articles",
                            link: `/sota/noreview`,
                            icon: <DeploymentUnitOutlined />,
                            requireConfig: true,
                        },
                    ],
                });
            }
        }

        if (config?.risk?.enabled) {
            projectMenu.push({
                label: "Risk Analysis",
                icon: <WarningOutlined />,
                children: [
                    {
                        label: "MAUDE",
                        link: "/adverse-events",
                        icon: <DeploymentUnitOutlined />,
                    },
                    {
                        label: "TPLC",
                        link: "/tplc",
                        icon: <DeploymentUnitOutlined />,
                    },
                    {
                        label: "Patient Problems",
                        link: "/patient-problems",
                        icon: <DeploymentUnitOutlined />,
                    },
                ],
            });
        }

        if (config?.sourceDocuments?.enabled) {
            projectMenu.push({
                label: "Source Documents",
                icon: <HddOutlined />,
                children: [
                    {
                        label: "Categories",
                        link: `/document/categories`,
                        icon: <DeploymentUnitOutlined />,
                    },
                    {
                        label: "Bookmarks",
                        link: `/document/bookmarks`,
                        icon: <DeploymentUnitOutlined />,
                    },
                ],
            });
        }

        if (config?.reportDocuments?.enabled) {
            projectMenu.push({
                label: "Reports",
                icon: <FileZipOutlined />,
                children: [
                    {
                        label: "Documents",
                        link: `/reports/documents`,
                        icon: <FormOutlined />,
                    },
                    {
                        label: "Dictionary",
                        link: `/dictionary`,
                        icon: <ReadOutlined />,
                    },
                    {
                        label: "References",
                        link: `/citation`,
                        icon: <ExceptionOutlined />,
                    },
                    {
                        label: "Abbreviation",
                        link: `/abbreviation`,
                        icon: <ReadOutlined />,
                    },
                ],
            });
        }

        projectMenu.push({
            label: "Activity Logs",
            link: "/activity-logs",
            icon: <FileSearchOutlined />,
        });
    }

    return projectMenu;
};

const processLinks = (items, args = {}) => {
    return items
        .map((item) => {
            item.key = item.key || item.link || item.label;

            item.allow = true;
            if (item.when) {
                item.allow = session.can(item.when);
            }

            if (item.children) {
                item.children = processLinks(item.children, args);
            }

            if (item.link !== undefined) {
                item.url = `${args.base || ""}${item.link}${args.search || ""}`;
            }

            return item;
        })
        .filter((item) => {
            if (!item.allow) {
                return false;
            }

            if (item.children && item.children.length === 0) {
                return false;
            }

            return true;
        });
};

/**
 * Render the navigation
 */
const Navigation = observer(() => {
    const features = featuresStore.getFeaturesInSession();
    let location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        state.links = processLinks(mainLinks);
        state.back = null;

        // project setup
        if (location.pathname.match(/(\/r\/\w+\/setup)/)) {
            let links = configLinks({ projectMenu: true, features });
            let base = RegExp.$1;
            const [, , projectId] = location.pathname.match(/(\/r\/(.+)\/setup)/);

            state.links = processLinks(
                links.filter((item) => item.link),
                { base, search: location.search },
            );

            state.back = {
                label: "Back to project",
                link: params.get("referrer") || `/r/${projectId}`,
            };
        }

        // client configuration
        else if (location.pathname.match(/((\/clients\/\w+\/view\/config)\/\w+\/setup)/)) {
            let links = configLinks({ features });
            let base = RegExp.$1;
            let back = RegExp.$2;

            state.links = processLinks(links, { base });
            state.back = {
                label: "Back to the client",
                link: back,
            };
        }

        // render the project links
        else if (report.project) {
            let links = projectLinks(report.config, features).filter((link) => {
                if (link.requireConfig) {
                    return report.config?.isConfigured;
                }

                return true;
            });

            state.links = processLinks(links, {
                base: `/r/${report.project._id}`,
            });

            state.back = {
                label: "Back to all projects",
                link: "/projects",
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report.project, report.config?.isConfigured, report.config?.sota, location.pathname]);

    return (
        <div
            className={classNames({
                navigation: true,
                toggled: state.toggled,
            })}
        >
            <div className="backdrop" onClick={() => state.toggle()}></div>
            <div className="container">
                <div className="head">
                    {!state.toggled && <img src={logoName} className="logo" />}
                    {state.toggled && <img src={logoIcon} className="logo" />}

                    <div className="toggle" onClick={() => state.toggle()}>
                        {state.toggled ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                </div>

                <div className="entries">
                    <Menu
                        links={state.links}
                        back={state.back}
                        project={report.project?._id}
                        defaultOpenKeys={["DUE", "SoTA"]}
                    />
                </div>
            </div>
        </div>
    );
});

export default Navigation;
