import React from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Menu, Dropdown } from "antd";
import {
    TeamOutlined,
    BookOutlined,
    FileSearchOutlined,
    DeploymentUnitOutlined,
    SettingOutlined,
    WarningOutlined,
    ProfileOutlined,
    DownOutlined,
    FileZipOutlined,
} from "@ant-design/icons";

import { Page } from "@app/components/page";
import confirm from "@app/components/confirm";
import Section from "@app/components/setup/section";
import { ProjectStatus } from "@app/constants";
import format from "@app/lib/format";

import setup from "@app/state/store/projects/setup";
import session from "@app/state/store/session";

const Setup = observer(() => {
    const { id } = useParams();
    const history = useHistory();

    const projectManage = session.can("project.manage");
    const config = setup.config;
    const project = setup.project;
    const live = project?.status === "draft" ? false : true;

    React.useEffect(() => {
        setup.load(id);

        return function () {
            setup.unload();
        };
    }, [id]);

    if (!project || !projectManage || !id || !config) {
        return null;
    }

    /**
     * Close the setup
     */
    const close = () => {
        history.push(`/projects`);
    };

    /**
     * Change project status (to Active/Complete/hold)
     */
    const updateStatus = async (status) => {
        let proceed = await confirm("Are you sure you want to change the project status?");
        if (proceed) {
            await setup.status(status);
        }
    };

    /**
     * Publish the project
     */
    const publish = async () => {
        let proceed = await confirm("Are you sure you want to go live?");
        if (proceed) {
            await setup.status(ProjectStatus.ACTIVE);
            history.push(`/r/${project._id}`);
        }
    };

    const status = Object.values(ProjectStatus).filter(
        (status) => ![ProjectStatus.DRAFT, project?.status].includes(status),
    );

    const statusMenu = (
        <Menu className="menu">
            {status.map((option) => {
                return (
                    <Menu.Item
                        key={option}
                        onClick={() => {
                            updateStatus(option);
                        }}
                    >
                        {format.projectStatus(option)}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const title = `Project Setup (${project.statusLabel})`;

    return (
        <Page className="setup">
            <Page.Header title={title} closable onClose={close}>
                {!live && (
                    <Page.Header.Right>
                        <Button onClick={() => publish()} type="primary">
                            Publish
                        </Button>
                    </Page.Header.Right>
                )}
                {live && (
                    <Page.Header.Right>
                        <Dropdown overlay={statusMenu} trigger="click" placement="bottomRight">
                            <Button>
                                Change Status <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Page.Header.Right>
                )}
            </Page.Header>

            <Page.Body maxWidth={800}>
                <div className="sections">
                    <Section link="./setup/general" title="General" icon={<SettingOutlined />} />
                    <Section link="./setup/team" title="Team" icon={<TeamOutlined />} />
                    <Section link="./setup/slr" title="DUE" icon={<FileSearchOutlined />} />
                    <Section link="./setup/sota" title="SoTA" icon={<DeploymentUnitOutlined />} />
                    <Section
                        link="./setup/source-documents"
                        title="Source Documents"
                        icon={<ProfileOutlined />}
                    />
                    <Section link="./setup/risk" title="Risk Analysis" icon={<WarningOutlined />} />
                    <Section
                        link="./setup/reports"
                        title="Literature Reports"
                        icon={<BookOutlined />}
                    />

                    <Section
                        link="./setup/report-documents"
                        title="Report Documents"
                        icon={<FileZipOutlined />}
                    />
                </div>
            </Page.Body>
        </Page>
    );
});

export default Setup;
