import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import Modal from "@app/components/modal";
import notify from "@app/components/notify";

const CustomDatabaseForm = observer(({ item, onAdd, onEdit, onCancel }) => {
    const [form] = Form.useForm();
    const refName = useRef(null);

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                name: item.name,
            });
        }

        if (refName.current) {
            refName.current.focus();
        }
    }, [form, item]);

    const title = (item ? "Edit" : "Add") + " custom database";

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    const save = (data) => {
        const name = data.name.trim();
        if (item) {
            onEdit({ ...item, name });
        } else {
            onAdd(name);
        }
    };

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="database-form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the database name",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={refName} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default CustomDatabaseForm;
