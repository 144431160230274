import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Page from "@app/components/page/page";
import Loader from "@app/components/loader/content";
import ReviewOverview from "@app/components/setup/report-documents/review/overview";
import StructureOverview from "@app/components/setup/report-documents/structure/overview";
import MetadataOverview from "@app/components/setup/report-documents/metadata/overview";

import documentsStore from "@app/state/store/report-document/report-documents";
import setup from "@app/state/store/projects/setup";

const SetupReportDocuments = observer(() => {
    const history = useHistory();
    const { id, documentId } = useParams();
    const reportDocument = documentsStore.reportDocument;
    const readonly = setup.isComplete;

    useEffect(() => {
        if (id && documentId) {
            documentsStore.setProjectId(id);
            documentsStore.load(documentId);
        }
    }, [id, documentId]);

    const close = () => {
        history.push(`/r/${id}/setup/report-documents`);
    };

    const onReviewEdit = () => {
        history.push(`./${documentId}/review`);
    };

    const onStructureEdit = () => {
        history.push(`./${documentId}/structure`);
    };

    const onMetadataEdit = () => {
        history.push(`./${documentId}/metadata`);
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>{reportDocument?.name ?? "Loading..."}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1000}>
                {reportDocument && (
                    <>
                        <ReviewOverview
                            document={reportDocument}
                            onEdit={onReviewEdit}
                            readonly={readonly}
                        />
                        <MetadataOverview
                            document={reportDocument}
                            onEdit={onMetadataEdit}
                            readonly={readonly}
                        />
                        <StructureOverview
                            document={reportDocument}
                            sections={documentsStore.reportDocumentSections}
                            onEdit={onStructureEdit}
                            readonly={readonly}
                        />
                    </>
                )}

                <Loader loading={!reportDocument} />
            </Page.Body>
        </Page>
    );
});

export default SetupReportDocuments;
