import TableRowOriginal from "@tiptap/extension-table-row";
import { mergeAttributes } from "@tiptap/core";
const TableRow = TableRowOriginal.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            isFootnote: {
                default: false,
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        const attrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
        if (attrs.isFootnote) {
            attrs.class = (attrs.class || "") + " table-footnote";
        }
        return ["tr", attrs, 0];
    },
});

export default TableRow;
