import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import { Button, Tooltip, Dropdown } from "antd";
import SymbolsMenu from "@app/components/symbol-picker/symbol-menu";

import "quill/dist/quill.snow.css";
import "./styles/picker.scss";

const TextInput = ({
    value = "",
    onChange,
    readOnly,
    isBlock = true,
    boundParent,
    children,
    ...rest
}) => {
    const sanitiseContent = (html) => {
        let content = html;
        if (!isBlock) {
            content = content
                .replaceAll(/<p>/g, "<span>")
                .replaceAll(/<\/p>/g, "</span>")
                .replaceAll("<br>", "")
                .replaceAll(/\n/g, "");
        }

        return content;
    };

    const createBindings = () => {
        const bindings = {
            // disable tab
            tab: false,
        };

        if (!isBlock) {
            // disable enter
            bindings.enter = {
                key: 13,
                handler: () => {},
            };
            bindings.shift_enter = {
                key: 13,
                shiftKey: true,
                handler: () => {},
            };
        }

        return bindings;
    };

    // create matchers for paste
    const createMatchers = () => {
        const matchers = [];

        if (!isBlock) {
            matchers.push([
                Node.ELEMENT_NODE,
                (node, delta) => {
                    delta.ops = delta.ops.map((op) => {
                        op.insert = sanitiseContent(op.insert);

                        // disable pasting of list
                        delete op.attributes?.list;

                        return op;
                    });

                    return delta;
                },
            ]);
        }

        return matchers;
    };

    const options = {
        theme: "snow",
        modules: {
            toolbar: "#toolbar",
            keyboard: {
                bindings: createBindings(),
            },
            clipboard: {
                matchers: createMatchers(),
            },
        },
        formats: ["bold", "italic", "underline", "script", "link"],
        placeholder: "",
        bounds: boundParent || document.body,
        readOnly,
    };

    if (readOnly) {
        options.modules.toolbar = null;
    }

    const { quill, quillRef } = useQuill(options);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (quill && !loaded) {
            const content = quill.clipboard.convert(value);
            quill.setContents(content);

            const textChangeHandler = () => {
                const html = sanitiseContent(quill.container.firstChild.innerHTML);
                onChange && onChange(html);
            };
            quill.on("text-change", textChangeHandler);
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill, value, loaded, onChange]);

    const insertSymbol = (item) => {
        const selection = quill.getSelection();
        if (selection?.length === 0) {
            const format = quill.getFormat(selection.index);
            quill.insertText(selection.index, item.value, format);
        }

        // close the menu
        const btn = document.getElementById("symbol-button");
        btn && btn.click();
    };

    return (
        <>
            <div id="toolbar">
                <div>
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                </div>
                <div>
                    <button className="ql-script" value="sub"></button>
                    <button className="ql-script" value="super"></button>
                </div>
                <div>
                    <button className="ql-link"></button>
                </div>
                <div>
                    <Dropdown overlay={<SymbolsMenu onInsert={insertSymbol} />} trigger={["click"]}>
                        <Tooltip title="Symbol Picker" placement="top">
                            <Button id="symbol-button" className="symbol-picker">
                                Ω
                            </Button>
                        </Tooltip>
                    </Dropdown>
                </div>
            </div>

            <div {...rest}>
                <div
                    id="dictionary-editor"
                    className="editor-container"
                    ref={quillRef}
                    tabIndex="0"
                />
                {children}
            </div>
        </>
    );
};

export default TextInput;
