import React, { useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { observer, useLocalStore } from "mobx-react";
import { NodeViewWrapper } from "@tiptap/react";
import { NodeSelection } from "@tiptap/pm/state";
import _ from "lodash";
import classes from "classnames";
import CaptionModal from "@app/components/caption/modal";
import { default as qs } from "qs";
import { ReportDocumentSection } from "@app/constants";

import imageStore from "@app/state/store/report-document/image";
import documentStore from "@app/state/store/report-document/document";
import versionStore from "@app/state/store/report-document/version";
import commentStore from "@app/state/store/report-document/comment";

import { parseCaptionText } from "@app/components/report-document/editor/modules/diff/diff-utility";

import "./style.scoped.scss";

export default observer(
    ({ editor, node, extension, updateAttributes, selected, getPos, deleteNode, view }) => {
        const { caption, "data-diff-node": change, "data-diff-id": diffId, comment } =
            node.attrs || {};
        const isEditable = editor.view.editable;
        const ref = useRef();
        const location = useLocation();
        const figureData = documentStore.figureDetails(node.attrs.id) ?? {};
        const versionId = versionStore.versionId;
        const isHighlighted = comment && comment.includes(commentStore.focusedComment);
        const status =
            commentStore.focusedComment &&
            commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;

        const readonly = !selected || extension.options.readonly || !!versionId;
        const hasCaption = !_.isEmpty(caption);
        const displayedCaption = parseCaptionText(figureData.fullCaption);

        const DIRECTION = {
            left: { key: "left", icon: "ew" },
            right: { key: "right", icon: "ew" },
            top: { key: "top", icon: "ns" },
            bottom: { key: "bottom", icon: "ns" },
            topLeft: { key: "topLeft", icon: "nwse" },
            topRight: { key: "topRight", icon: "nesw" },
            bottomLeft: { key: "bottomLeft", icon: "nesw" },
            bottomRight: { key: "bottomRight", icon: "nwse" },
        };

        const { image: ImageConstant } = ReportDocumentSection;

        const state = useLocalStore(() => ({
            isResizing: false,
            commitUpdateAttributes: false,
            isEditingCaption: false,
            lastX: 0,
            lastY: 0,
            aspectRatio: node.attrs.width / node.attrs.height,
            width: 0,
            height: 0,
            loading: false,
            maxWidth: ImageConstant.getMaxWidth(editor.view.dom.clientWidth),
            minWidth: ImageConstant.minWidth,
        }));

        const resizing = (e, direction) => {
            if (!state.isResizing) {
                return;
            }

            let grow;
            let diff;

            switch (direction) {
                case DIRECTION.left.key: {
                    if (state.lastX !== e.screenX) {
                        grow = state.lastX > e.screenX;
                        diff = grow ? state.lastX - e.screenX : e.screenX - state.lastX;
                    }
                    break;
                }
                case DIRECTION.right.key: {
                    if (state.lastX !== e.screenX) {
                        grow = state.lastX < e.screenX;
                        diff = grow ? e.screenX - state.lastX : state.lastX - e.screenX;
                    }
                    break;
                }
                case DIRECTION.top.key: {
                    if (state.lastY !== e.screenY) {
                        grow = state.lastY > e.screenY;
                        diff = grow ? state.lastY - e.screenY : e.screenY - state.lastY;
                    }
                    break;
                }
                case DIRECTION.bottom.key: {
                    if (state.lastY !== e.screenY) {
                        grow = state.lastY < e.screenY;
                        diff = grow ? e.screenY - state.lastY : state.lastY - e.screenY;
                    }
                    break;
                }
                case DIRECTION.topLeft.key: {
                    grow = state.lastY > e.screenY || state.lastX > e.screenX;
                    const yDiff = state.lastY - e.screenY;
                    const xDiff = state.lastX - e.screenX;
                    diff = yDiff > xDiff ? yDiff : xDiff;
                    break;
                }
                case DIRECTION.topRight.key: {
                    grow = state.lastY > e.screenY || state.lastX < e.screenX;
                    const yDiff = state.lastY - e.screenY;
                    const xDiff = e.screenX - state.lastX;
                    diff = yDiff > xDiff ? yDiff : xDiff;
                    break;
                }
                case DIRECTION.bottomLeft.key: {
                    grow = state.lastY < e.screenY || state.lastX > e.screenX;
                    const yDiff = e.screenY - state.lastY;
                    const xDiff = state.lastX - e.screenX;
                    diff = yDiff > xDiff ? yDiff : xDiff;
                    break;
                }
                case DIRECTION.bottomRight.key: {
                    grow = state.lastY < e.screenY || state.lastX < e.screenX;
                    const yDiff = e.screenY - state.lastY;
                    const xDiff = e.screenX - state.lastX;
                    diff = yDiff > xDiff ? yDiff : xDiff;
                    break;
                }
                default: {
                    return;
                }
            }

            if (grow !== undefined) {
                if (grow) {
                    resizeAspectRatio(true, diff);
                } else {
                    resizeAspectRatio(false, diff);
                }
            }

            state.lastX = e.screenX;
            state.lastY = e.screenY;
        };

        const configureElement = (element, direction, readonly) => {
            if (!element || readonly) {
                return;
            }

            const handleResizing = (e) => {
                document.body.style.cursor = `${DIRECTION[direction].icon}-resize`;
                resizing(e, direction);
            };

            element.addEventListener("mouseup", () => {
                state.isResizing = false;
                state.lastX = 0;
                state.lastY = 0;
            });

            element.addEventListener("mousedown", (e) => {
                state.isResizing = true;
                state.commitUpdateAttributes = false;
                state.lastX = e.screenX;
                state.lastY = e.screenY;

                window.addEventListener("mousemove", handleResizing);
                window.addEventListener("mouseup", () => {
                    state.isResizing = false;
                    state.commitUpdateAttributes = true;
                    document.body.style.cursor = "auto";

                    window.removeEventListener("mousemove", handleResizing);
                    window.removeEventListener("mouseup", null);
                });
            });
        };

        const rightRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.right.key, readonly);
            },
            [readonly],
        );

        const leftRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.left.key, readonly);
            },
            [readonly],
        );

        const topRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.top.key, readonly);
            },
            [readonly],
        );

        const bottomRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.bottom.key, readonly);
            },
            [readonly],
        );

        const topLeftRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.topLeft.key, readonly);
            },
            [readonly],
        );

        const topRightRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.topRight.key, readonly);
            },
            [readonly],
        );

        const bottomLeftRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.bottomLeft.key, readonly);
            },
            [readonly],
        );

        const bottomRightRef = useCallback(
            (element) => {
                configureElement(element, DIRECTION.bottomRight.key, readonly);
            },
            [readonly],
        );

        const resizeAspectRatio = (grow, diff) => {
            let calcW;
            let calcH;

            // multiply by 2 because the image is centered aligned
            let value = Math.abs(diff) * 2;

            if (grow) {
                calcH = state.height + value;
            } else {
                calcH = state.height - value;
            }

            // And calculate the width with the Aspect Ratio
            calcW = calcH * state.aspectRatio;

            // prevent the image from scalling the allowed max/min size
            if (calcW >= state.maxWidth || calcW <= state.minWidth) {
                return;
            }

            state.height = calcH;
            state.width = calcW;
        };

        const onEditCaption = useCallback(({ node: inputNode }) => {
            if (inputNode?.attrs?.id === node.attrs.id) {
                editCaption();
            }
        });

        const closeEditingCaption = () => {
            state.isEditingCaption = false;
        };

        const editCaption = () => {
            state.isEditingCaption = true;
        };

        const updateCaption = ({ caption }) => {
            closeEditingCaption();
            updateAttributes({ caption });

            documentStore.updateFigureCaption(node.attrs.id, caption);
        };

        const onRemoveImage = ({ node: inputNode }) => {
            if (inputNode?.attrs?.id === node.attrs.id) {
                editor.commands.deleteSelection();
            }
        };

        const configureForTableCell = () => {
            return setTimeout(() => {
                const imgEl = document.getElementById(node.attrs.id);
                const parentCell = imgEl ? imgEl.closest("td") || imgEl.closest("th") : null;

                if (parentCell) {
                    state.maxWidth = ImageConstant.getCellMaxWidth(parentCell.clientWidth);

                    if (node.attrs.width && node.attrs.width > state.maxWidth) {
                        state.width = state.maxWidth;
                        state.height = state.maxWidth / state.aspectRatio;
                        state.commitUpdateAttributes = true;
                    }
                }
            }, [0]);
        };

        const updateState = () => {
            return setTimeout(() => {
                const newAspectRatio = node.attrs.width / node.attrs.height;
                let newWidth = node.attrs.width;
                if (node.attrs.width > state.maxWidth) {
                    newWidth = state.maxWidth;
                } else if (node.attrs.width < state.minWidth) {
                    newWidth = state.minWidth;
                }
                const newHeight = newWidth / newAspectRatio;
                if (
                    state.aspectRatio !== newAspectRatio ||
                    state.width !== newWidth ||
                    state.height !== newHeight
                ) {
                    state.aspectRatio = newAspectRatio;
                    state.width = newWidth;
                    state.height = newHeight;
                    state.commitUpdateAttributes = true;
                }
            }, [0]);
        };

        useEffect(() => {
            if (!editor) {
                return;
            }

            editor.on("editImageCaption", onEditCaption);
            editor.on("removeImage", onRemoveImage);
            return () => {
                editor.off("editImageCaption", onEditCaption);
                editor.off("removeImage", onRemoveImage);
            };
        }, [editor]);

        useEffect(() => {
            if (state.commitUpdateAttributes && state.width && state.height) {
                if (state.width !== node.attrs.width || state.height !== node.attrs.height) {
                    updateAttributes({ width: state.width, height: state.height });
                    state.commitUpdateAttributes = false;
                }
            }
        }, [state.commitUpdateAttributes]);

        useEffect(() => {
            let updateStateTimer;
            const updateTableTimer = configureForTableCell();

            if (!node.attrs.processed) {
                const image = {
                    type: "src",
                    value: node.attrs.src,
                    caption: node.attrs.caption,
                };
                extension.options.processImage(image, (newImage) => {
                    if (newImage) {
                        updateAttributes(newImage);
                    } else {
                        // handle unsupported image
                        deleteNode(node);
                    }
                });
            } else {
                updateStateTimer = updateState();
            }

            return () => {
                clearTimeout(updateTableTimer);
                clearTimeout(updateStateTimer);
            };
        }, [node.attrs.processed]);

        useEffect(() => {
            if (imageStore.loading) {
                editor.commands.setLoading();
                state.loading = imageStore.loading;
            } else {
                if (state.loading !== imageStore.loading) {
                    editor.commands.unsetLoading();
                    state.loading = imageStore.loading;
                }
            }
        }, [imageStore.loading]);

        useEffect(() => {
            if (ref && ref.current) {
                const params = qs.parse(location.search, { ignoreQueryPrefix: true });
                if (params.node === node.attrs.id) {
                    ref.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
        }, [location]);

        const handleContextMenu = (event) => {
            event.preventDefault();
            if (node.type.spec.selectable) {
                editor.chain().focus().setNodeSelection(getPos()).run();
            }
        };

        useEffect(() => {
            if (ref.current) {
                const handleMouseDown = (event) => {
                    event.preventDefault();

                    const { tr } = editor.view.state;

                    if (node.type.isLeaf) {
                        tr.setSelection(NodeSelection.create(editor.view.state.doc, getPos()));
                        editor.view.dispatch(tr);
                    }
                };

                const nodeRef = ref.current;
                nodeRef.addEventListener("contextmenu", handleContextMenu);

                if (!isEditable) {
                    nodeRef.addEventListener("mousedown", handleMouseDown);
                }

                return () => {
                    nodeRef.removeEventListener("contextmenu", handleContextMenu);
                    nodeRef.removeEventListener("mousedown", handleMouseDown);
                };
            }
        }, [ref.current, isEditable]);

        if (!node.attrs.processed) {
            return null;
        }

        const invalid = false;

        return (
            <NodeViewWrapper ref={ref} className="content-image-wrapper">
                <div
                    className={classes({
                        tableContainer: true,
                        readonly,
                        inserted: change === "ins",
                        deleted: change === "del",
                    })}
                    data-diff-id={diffId}
                >
                    <div>
                        <div className="topLeftBottomRightCell" ref={topLeftRef}></div>
                        <div className="topbottomCell" ref={topRef}></div>
                        <div className="topRightBottomLeftCell" ref={topRightRef}></div>
                    </div>
                    <div>
                        <div className="sideCell" ref={leftRef}></div>
                        <figure
                            className={classes({
                                imageContainer: true,
                                selected: !readonly,
                                pending: isHighlighted && status === "pending",
                                resolved: isHighlighted && status === "resolved",
                                invalid,
                            })}
                        >
                            <img
                                id={node.attrs.id}
                                className={classes({ imageStyle: true })}
                                src={node.attrs.src}
                                width={state.width}
                                height={state.height}
                            />
                        </figure>
                        <div className="sideCell" ref={rightRef}></div>
                    </div>
                    <div>
                        <div className="topRightBottomLeftCell" ref={bottomLeftRef}></div>
                        <div className="topbottomCell" ref={bottomRef}></div>
                        <div className="topLeftBottomRightCell" ref={bottomRightRef}></div>
                    </div>
                </div>
                {hasCaption && (
                    <div
                        className={classes({
                            caption: true,
                            editable: !extension.options.readonly,
                        })}
                        dangerouslySetInnerHTML={{ __html: displayedCaption }}
                    />
                )}
                <CaptionModal
                    title={"Edit Figure Caption"}
                    caption={caption}
                    open={state.isEditingCaption}
                    onSubmit={updateCaption}
                    onCancel={closeEditingCaption}
                />
            </NodeViewWrapper>
        );
    },
);
