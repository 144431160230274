import BaseStore from "../base";
import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";
import http from "@app/lib/http";
import ReportDocument from "@app/state/model/report-document/report-document";
import ReportDocumentSection from "@app/state/model/report-document/report-document-section";
import notify from "@app/components/notify";
import events from "@app/lib/store/events";
import { Role } from "@app/constants";

import sessionStore from "@app/state/store/session";
import report from "@app/state/store/report";

const STAGGER_DOCS = uuid();
const STAGGER_DOCUMENT = uuid();
const STAGGER_SECTIONS = uuid();

export class ReportDocuments extends BaseStore {
    observable() {
        return {
            projectId: null,
            clientId: null,
            reportDocument: null,
            reportDocuments: [],
            reportDocumentSections: [],
        };
    }

    getAPIBasePath() {
        if (this.projectId) {
            return `/project/${this.projectId}`;
        }

        if (this.clientId) {
            return `/client/${this.clientId}`;
        }

        return null;
    }

    @action
    async setProjectId(projectId) {
        if (this.projectId !== projectId) {
            this.reset();
            this.projectId = projectId;
        }
    }

    @action
    async setClientId(clientId) {
        if (this.clientId !== clientId) {
            this.reset();
            this.clientId = clientId;
        }
    }

    @action
    async loadReportDocuments(params) {
        if (!this.projectId && !this.clientId) {
            return;
        }

        try {
            const basePath = this.getAPIBasePath();
            if (basePath) {
                const { data } = await http
                    .get(`${basePath}/report-documents/list?metaData=${params?.metaData}`)
                    .stagger(STAGGER_DOCS);

                this.reportDocuments = data.map((doc) => new ReportDocument(doc));
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get report documents");
        }
    }

    @action
    async getReportDocuments({ projectId, clientId, metaData }) {
        if (!projectId && !clientId) {
            return;
        }

        try {
            const basePath = projectId ? `/project/${projectId}` : `/client/${clientId}`;
            const { data } = await http
                .get(`${basePath}/report-documents/list?metaData=${metaData}`)
                .stagger(STAGGER_DOCS);

            return data.map((doc) => new ReportDocument(doc));
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get report documents");
        }
    }

    @action
    async getClientReportDocuments(clientId) {
        if (!clientId) {
            return [];
        }

        try {
            const { data } = await http.get(`/client/${clientId}/report-documents/list`);
            return data.map((doc) => new ReportDocument(doc));
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get report documents");
        }
    }

    @action
    async getReportDocument(id) {
        if (!this.projectId && !this.clientId) {
            return;
        }

        if (!id) {
            return;
        }

        // reset the current document when loading a new one
        if (this.reportDocument && this.reportDocument._id !== id) {
            this.unload();
        }

        try {
            const basePath = this.getAPIBasePath();
            if (basePath) {
                const { data } = await http
                    .get(`${basePath}/report-documents/${id}`)
                    .stagger(STAGGER_DOCUMENT);

                if (data?._id) {
                    return new ReportDocument(data);
                }
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get report document");
        }
    }

    @action
    async updateCurrentReportDocumentMetaData(id) {
        const reportDocument = await this.getReportDocument(id);

        if (reportDocument?.metaData) {
            this.reportDocument.metaData = reportDocument.metaData;
        }
    }

    @action
    async load(id) {
        try {
            this.reportDocument = await this.getReportDocument(id);

            if (this.reportDocument) {
                // load the sections
                await this.getReportDocumentSections(id);
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot load report document");
        }
    }

    @action
    async unload() {
        this.reportDocument = null;
        this.reportDocumentSections = [];
    }

    @action
    async delete(id) {
        const basePath = this.getAPIBasePath();

        try {
            await http.delete(`${basePath}/report-documents/${id}`);
            events.emit("report-document.delete", id);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot delete report document");
        }
    }

    @action
    async create(reportDocument) {
        if (!reportDocument) {
            return;
        }

        const basePath = this.getAPIBasePath();

        this.unload();

        try {
            let { data } = await http.put(`${basePath}/report-documents`, {
                reportDocument,
            });

            this.reportDocument = new ReportDocument(data);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot create report document");
        }
    }

    @action
    async update(id, reportDocument) {
        if (!reportDocument) {
            return;
        }

        const basePath = this.getAPIBasePath();

        try {
            let { data } = await http.post(`${basePath}/report-documents/${id}`, {
                reportDocument,
            });

            this.reportDocument = new ReportDocument(data);
            events.emit("reportDocument.viewer.reload");
            events.emit("reportDocumentList.reload");
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot update report document");
        }
    }

    @action
    async updateReportDocument(type, entry) {
        switch (type) {
            case "create":
                await this.create(entry);
                break;
            case "update":
                await this.update(entry._id, entry);
                break;
            case "delete":
                await this.delete(entry._id);
                break;
            default:
                break;
        }
    }

    @action
    async getReportDocumentSections(reportDocumentId) {
        try {
            const basePath = this.getAPIBasePath();
            if (basePath) {
                let { data } = await http
                    .get(`${basePath}/report-documents/${reportDocumentId}/sections`)
                    .stagger(STAGGER_SECTIONS);

                this.reportDocumentSections = data.map(
                    (section) => new ReportDocumentSection(section),
                );
                return this.reportDocumentSections;
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get report document sections");
        }
    }

    @action
    async saveReportDocumentSections(reportDocumentId, sections) {
        const basePath = this.getAPIBasePath();

        try {
            let { data } = await http.post(
                `${basePath}/report-documents/${reportDocumentId}/sections`,
                {
                    sections,
                },
            );

            if (this.reportDocument?._id === reportDocumentId) {
                this.reportDocumentSections = data.map(
                    (section) => new ReportDocumentSection(section),
                );
            }

            return this.reportDocumentSections;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot save report document sections");
        }
    }

    @computed
    get canUserStartReview() {
        const isAdmin = sessionStore.user.roles.find((role) =>
            [Role.ACCOUNT_ADMIN, Role.ADMIN].includes(role),
        );
        if (isAdmin) {
            return true;
        } else {
            // check the project roles
            const projectRole = report.project.userRoles.find((role) =>
                [Role.PROJECT_MANAGER, Role.LEAD_AUTHOR].includes(role),
            );

            return !!projectRole;
        }
    }

    @action
    async review(id) {
        const basePath = this.getAPIBasePath();

        try {
            const { data } = await http.post(`${basePath}/report-documents/${id}/review`);
            this.reportDocument = new ReportDocument(data);

            // reload the report document view
            events.emit("reportDocument.viewer.reload");
            events.emit("reportDocumentList.reload");
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Report document review error!");
        }
    }

    @computed
    get citationsSet() {
        const citations = new Set();

        this.reportDocumentSections.forEach((section) =>
            section.citations?.forEach((citation) => citations.add(citation)),
        );

        return Array.from(citations);
    }

    @computed
    get tableSet() {
        const tables = new Set();

        this.reportDocumentSections.forEach((section) =>
            section.tables?.forEach((table) => tables.add(table)),
        );

        return Array.from(tables);
    }

    @computed
    get figureSet() {
        const figures = new Set();

        this.reportDocumentSections.forEach((section) =>
            section.figures?.forEach((figure) => figures.add(figure)),
        );

        return Array.from(figures);
    }

    @action getUserReviewer(reviewers) {
        const reviewer = reviewers?.find((reviewer) => reviewer._id === sessionStore.user._id);
        return reviewer;
    }

    @action canAccess(reportDocument) {
        let access = true;

        if (!reportDocument) {
            access = false;
        } else {
            const isAdminRole = sessionStore.user.roles.find((role) =>
                [Role.ACCOUNT_ADMIN, Role.ADMIN].includes(role),
            );

            if (!isAdminRole) {
                const isOnlyReviewerProjectRole =
                    report.project.userRoles.length === 1 &&
                    report.project.userRoles[0] === Role.REVIEWER;

                const isOnlyClientProjectRole =
                    report.project.userRoles.length === 1 &&
                    report.project.userRoles[0] === Role.CLIENT;

                const isDocumentReviewer = reportDocument.reviewConfig?.stages?.find((stage) =>
                    this.getUserReviewer(stage.reviewers),
                );

                const isCurrentStageReviewer = this.getUserReviewer(
                    reportDocument.review?.stage?.reviewers,
                );

                const hadCompletedReview = reportDocument.review?.history?.find((history) =>
                    this.getUserReviewer(history.reviewers),
                );

                if (isOnlyReviewerProjectRole) {
                    if (isDocumentReviewer) {
                        if (!hadCompletedReview && !isCurrentStageReviewer) {
                            access = false;
                        }
                    } else {
                        access = false;
                    }
                } else if (isOnlyClientProjectRole) {
                    if (isDocumentReviewer) {
                        if (!hadCompletedReview && !isCurrentStageReviewer) {
                            access = false;
                        }
                    } else {
                        access = false;
                    }
                }
            }
        }

        return access;
    }
}

export default new ReportDocuments();
