import React from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { LeftCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Menu } from "antd";

import state from "./state";
import session from "@app/state/store/session";

/**
 * Render a menu item based on an item definition
 * @param {Object} item
 */
const renderItem = (item) => {
    if (item.url) {
        return (
            <Menu.Item key={item.key} icon={item.icon} className={item.mark ? "mark" : null}>
                <Link to={item.url}>{item.label}</Link>
            </Menu.Item>
        );
    }

    if (item.children) {
        return (
            <Menu.SubMenu title={item.label} key={item.key} icon={item.icon}>
                {state.toggled && renderTitle(item)}
                {item.children.map(renderItem)}
            </Menu.SubMenu>
        );
    }

    return null;
};

const renderTitle = (item) => {
    return (
        <Menu.Item disabled key={`title=${item.key}`} className="submenu-title">
            {item.label}
        </Menu.Item>
    );
};

const MenuSection = ({ children, ...rest }) => {
    return (
        <Menu
            mode="inline"
            inlineCollapsed={state.collapsed}
            onClick={() => state.onClick()}
            {...rest}
        >
            {children}
        </Menu>
    );
};

/**
 * A main navigation menu component
 */
const MainMenu = observer(({ links, back, project }) => {
    const location = useLocation();
    let selected = [];

    const isProjectSetup = location.pathname.match(/(\/projects\/\w+\/setup)/);
    const projectManage = session.can("project.manage");

    /**
     * Checks if a menu item is selected
     */
    const isSelected = (item) => {
        if (item.url) {
            // if the menu item is marked as exact we will compare
            // the exact path with the current location. If not marked
            // as exact will check if the location starts with the link
            if (item.exact && item.url === location.pathname) {
                selected.push(item.key);
                return true;
            } else if (!item.exact && location.pathname.startsWith(item.url)) {
                selected.push(item.key);
                return true;
            }
        }

        if (item.children?.some((child) => isSelected(child))) {
            selected.push(item.key);
            return true;
        }
    };

    links.forEach(isSelected);

    if (!state.collapsed && selected.length && !state.openKeys) {
        state.onOpenChange(selected);
    }

    return (
        <>
            {back && (
                <div className="back">
                    <MenuSection
                        onClick={state.onClick}
                        openKeys={state.openKeys}
                        onOpenChange={(keys) => {
                            state.onOpenChange(keys);
                        }}
                    >
                        <Menu.Item icon={<LeftCircleOutlined />}>
                            <Link to={back.link}>{back.label}</Link>
                        </Menu.Item>
                    </MenuSection>
                </div>
            )}
            <MenuSection
                openKeys={state.openKeys}
                onClick={state.onClick}
                onOpenChange={(keys) => {
                    state.onOpenChange(keys);
                }}
                selectedKeys={selected}
            >
                {links.map(renderItem)}
            </MenuSection>
            {project && !isProjectSetup && projectManage && (
                <div className="settings">
                    <MenuSection>
                        <Menu.Item icon={<SettingOutlined />}>
                            <Link
                                to={{
                                    pathname: `/r/${project}/setup`,
                                    search: `referrer=${location.pathname}`,
                                }}
                            >
                                Project Settings
                            </Link>
                        </Menu.Item>
                    </MenuSection>
                </div>
            )}
        </>
    );
});

export default MainMenu;
