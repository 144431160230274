import React, { useState } from "react";
import { Button, Modal } from "antd";

import InsertModalContent from "./insert-modal-content";

function tokenize(text) {
    return text.toLowerCase().match(/\b(\w+)\b/g) || [];
}

function highlightText(text, tokens) {
    const tokenRegex = new RegExp(`(${tokens.join("|")})`, "gi");
    const parts = text.split(tokenRegex);

    return parts.map((part, index) =>
        tokenRegex.test(part) ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
                {part}
            </span>
        ) : (
            part
        ),
    );
}

function filterAndHighlightReferences(references, searchString) {
    const tokens = tokenize(searchString);

    if (tokens.length === 0) {
        return references.map((ref) => ({ ...ref, highlightedTitle: ref.title }));
    }

    return references
        .map((ref) => {
            let score = 0;

            const refKeys = Object.keys(ref);

            //  check if the value contains the token
            const compareValue = (value, token) => {
                return value.toString().toLowerCase().includes(token);
            };

            // Match authors against a token (first name or last name)
            const compareAuthors = (authors, token) => {
                return authors.some(
                    (item) =>
                        compareValue(item.firstName, token) || compareValue(item.lastName, token),
                );
            };

            // Check each token against each key in the reference object and increment the score if a match is found
            tokens.forEach((token) => {
                refKeys.forEach((key) => {
                    const value = ref[key];

                    if (
                        (typeof value === "string" || typeof value === "number") &&
                        compareValue(value, token)
                    ) {
                        score += 1;
                    } else if (Array.isArray(value)) {
                        if (key === "authors") {
                            if (compareAuthors(value, token)) {
                                score += 1;
                            }
                        }
                    } else if (key === "ids") {
                        const ids = Object.values(value);
                        if (ids.some((id) => compareValue(id, token))) {
                            score += 1;
                        }
                    }
                });
            });

            return {
                ...ref,
                score: score,
                highlightedTitle: highlightText(ref.title, tokens),
            };
        })
        .filter((ref) => ref.score > 0) // Keep only references with at least one matched token
        .sort((a, b) => b.score - a.score); // Sort by score in descending order
}

export const SuggestionList = ({ command, onExit, items }) => {
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState("");

    const filteredReferences = filterAndHighlightReferences(items, search);

    const onSetSearch = (event) => {
        setSearch(event?.target?.value || "");
    };

    const onCreateNew = () => {
        command({ addCitation: true });
        onExit();
        return;
    };

    const onInsert = () => {
        if (selected.length) {
            command({
                items: selected.map((_id) => ({
                    id: _id,
                })),
            });
        }
    };

    const onClickItem = (id) => {
        setSelected((value) => {
            if (value.includes(id)) {
                return value.filter((v) => v !== id);
            }

            return value.concat(id);
        });
    };

    const onCancel = () => {
        command({ resetFocus: true });
        onExit();
        return;
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onInsert();
        }
    };

    return (
        <Modal
            className={"citation-suggestion"}
            title="Insert Reference"
            visible={true}
            footer={[
                <Button key="back" type="text" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="default" onClick={onCreateNew}>
                    Insert New
                </Button>,
                <Button key="link" type="primary" onClick={onInsert} disabled={!selected.length}>
                    Insert Selected
                </Button>,
            ]}
            maskClosable={true}
            keyboard={true}
            onCancel={onCancel}
        >
            <InsertModalContent
                onSetSearch={onSetSearch}
                search={search}
                onClickItem={onClickItem}
                selected={selected}
                filteredReferences={filteredReferences}
                onKeyDown={onKeyDown}
            />
        </Modal>
    );
};

export default SuggestionList;
