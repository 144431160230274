import { Plugin, PluginKey } from "@tiptap/pm/state";

export const cancelNewRowAfterFootnote = () => {
    return new Plugin({
        key: new PluginKey("cancelNewRowAfterFootnote"),
        filterTransaction: (tr) => {
            if (tr.docChanged) {
                const { doc, before } = tr;

                const findCountOfTablesWithRowAfterFootnote = (node) => {
                    const tables = node.content.content.filter((n) => n.type.name === "table");

                    return tables.reduce((acc, table) => {
                        const lastRow = table.content.content[table.content.content.length - 1];

                        const hasFootnote = table.content.content.some((n) => n.attrs.isFootnote);

                        return acc + (!lastRow.attrs.isFootnote && hasFootnote ? 1 : 0);
                    }, 0);
                };

                const countOfTablesWithRowAfterFootnoteBefore = findCountOfTablesWithRowAfterFootnote(
                    before,
                );
                const countOfTablesWithRowAfterFootnoteAfter = findCountOfTablesWithRowAfterFootnote(
                    doc,
                );

                if (
                    countOfTablesWithRowAfterFootnoteAfter > countOfTablesWithRowAfterFootnoteBefore
                ) {
                    return false;
                }
            }

            return true;
        },
    });
};
